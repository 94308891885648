@import 'src/styles/common.module';


.errorMessage{
    font-size: 14px;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 16px;
    background: $light-red;
    display: flex;
    width: 100%;
    overflow: auto;
    .message{
        flex: 1;
        color: $red;
    }
    svg {
        margin-right: 10px;
    }
 }



.errorMessageBackground{
    font-size: 14px;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 16px;
    background: $light-red;
    color: $red;
    align-items: center;
    display: flex;
    justify-content: center;
    svg {
        margin-right: 10px;
    }
 }

