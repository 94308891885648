@import 'src/styles/common.module';

.iucnTable{
    .logoContainer{
        display: flex;
        justify-content: left;
        align-items: left;
        width: 100%;
        height: 100%;

        .logo{
            color: #E42323;
            width: 32.85px;
            height: 29.17px;  
            margin-top: 10px;
            font-size: 24px;
        }

        .inverted{
            rotate: 180deg;
        }

        .title{
            margin-top: 10px;
            margin-left: 10px;
            color: $dark-teal;
            font-weight: $normal;
            font-size: 24px;
            span {
                margin-bottom: 5px;
            }
        }
    }

    .table{
        border-bottom: 1px solid $light-grey;
    }
    .tableHeader{
        border-top: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;
        th{
            background-color: transparent;
        }
        
    }
}

.iucnTable + .iucnTable{
    margin-top: 20px;
}