@import 'src/styles/common.module';

.textfield {
    fieldset {
        border-color: $dark-teal;
    }

    input,
    svg {
        color: $dark-teal;
        height: 25px;
    }

    input::placeholder {
        color: $dark-grey;
    }

}

.autocomplete {
    background-color: $white;

    & div.MuiOutlinedInput-root {
        padding: 0 9px !important;
    }
}