@import 'src/styles/common.module';

.userDetails {
    display: flex;
    gap: 16px;

    .avatar {
        height: 44px;
        width: 44px;
        text-align: center;
        padding: 7px;
        font-size: 20px;
        background-color: $medium-light-grey;
        color: $dark-grey;
        border-radius: 50%;
        font-weight: 400;
    }

    .info {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        gap: 4px;

        .name {
            font-size: 14px;
            color: $black;

            &Inactive {
                color: $dark-grey;
            }
        }

        .contact {
            font-size: 12px;
            color: $dark-grey;

            &Inactive {
                color: $grey;
            }
        }

        .icons {
            display: flex;
            gap: 24px;

            .adminIcon {
                display: flex;
                gap: 4px;
                color: $dark-blue;
                font-size: 12px;
                align-items: center;
            }
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0px 0 20px 70px;

    }
}