@import 'src/styles/common.module';


.title{
    color: $dark-teal;
    font-size: 20px;
    margin-bottom: 16px;
}

.label{
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: $dark-teal;
}

.labelTitle{
    font-weight: $light;
}

.tooltipContent {
    color: $dark-grey;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
}

.tooltipCloseIcon {
    cursor: pointer;
    color: $dark-grey;
    position: absolute;
    top: 20px;
    right: 20px;
}