@import 'src/styles/common.module';

$globalMargin: 32px;
$filtersHeight: 85px;
$breadcrumbsHeight: 36px;

.reviewAndSubmit{
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.container {
    display: flex;
    flex-direction: row;
    box-shadow: inset 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);

    .mainContent {
        transition: width .2s linear;
        flex: 1;
    }

    .sidebar {
        width: 350px;
        background-color: $white;
        overflow: auto;
    }

}

.bottomNavigation {
    display: flex;
    justify-content: space-between;

}