@import 'src/styles/common.module';

.speciesHighlightContainer {
    display: flex;
    background-color: $white;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0.4, 0.4, 0.4, 0.2);
    position: relative;

    @media only screen and (min-width: 920px) and (max-width: 1199px) {
        flex-direction: column;
        row-gap: 32px;
    }

    .header {
        position: absolute;
        padding: 15px 0 0 30px;
        color: $white;
    }

    .subHeader {
        margin: 20px 0;

        .top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }
    }

    .imageContainer {
        position: relative;
    }

    .description {
        color: $dark-grey;
    }

    .rowContainer {
        display: flex;
        gap: 32px;
        position: relative;

        @media only screen and (max-width: 919px) {
            display: block;
        }

        .imageContainer {
            img {
                width: 100%;
                border-radius: 10px;
            }
            @media only screen and (min-width: 1536px) {
                min-width: 720px;
                max-width: 720px;
            }

            @media only screen and (min-width: 1200px) and (max-width: 1535px) {
                min-width: 520px;
                max-width: 520px;
            }

            @media only screen and (min-width: 920px) and (max-width: 1199px) {
                min-width: 416px;
                max-width: 416px;
            }

            @media only screen and (max-width: 919px) {
                width: 100%;
            }
        }
        .contentContainer {
            @media only screen and (min-width: 1536px) {
                width: auto;
            }

            @media only screen and (min-width: 1200px) and (max-width: 1535px) {
                width: auto;
            }
            @media only screen and (min-width: 920px) and (max-width: 1199px) {
                width: auto;
            }
        }

    .titleContainer {
         @media only screen and (max-width: 919px) {
            position: absolute;
            top:0;
            padding:16px;
        }
    }


    }
    .titleContainer {
        .subTitle, .title {
            @media only screen and (max-width: 919px) {
                color:  $primary-navy;
            }
        }
    }

    .showOnMapButton {
        margin-top: 24px;
        margin-left: auto;
    }

    .columnContainer {
        display: flex;
        flex-direction: column;
        column-gap: 32px;
        @media only screen and (max-width: 919px) {
            display: block;
        }
        .imageContainer {
            img {
                width: 100%;
                border-radius: 10px;
            }

            @media only screen and (min-width: 1536px) {
                width: 720px;
            }

            @media only screen and (min-width: 1200px) and (max-width: 1535px) {
                width: 520px;
            }

            @media only screen and (min-width: 920px) and (max-width: 1199px) {
                min-width: 416px;
            }

            @media only screen and (max-width: 919px) {
                width: 100%;
                margin-bottom: 32px;
            }
        }

        .contentContainer {
            padding: 10px;
            height: max-content;
        }
    }
}

.speciesHighlightContainerNoData {
    display: block;
    border-radius: 5px;
    padding: 10px;

    .subTitle {
        color: $black;
    }
}

.title {
    font-size: 24px;
    font-weight: $normal;
    font-style: normal;
    
    @media only screen and (max-width: 919px) {
        color:$white !important;
    }

}

.subTitle {
    color:  $primary-navy;
    font-size: 16px;
    font-weight: $light;
    line-height: 19px;
    margin-bottom: 24px;
    @media only screen and (max-width: 919px) {
        color: $white !important;
    }
}

.commonName {
    font-style: normal;
    font-weight: $normal;
    font-size: 17px;
    line-height: 20px;
    color: $dark-grey;
    margin-top: 10px;
}
.scientificName {
    color: $dark-teal;
    font-style: italic;
    font-weight: $normal;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
    @media only screen and (max-width: 919px) {
        margin-top: 32px;
    }
}

.descriptionContainer {
    display: flex;
    flex-direction: column;
}

.description {
    font-style: normal;
    font-weight: $normal;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color:  $primary-navy;
}
