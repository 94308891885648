@import 'src/styles/common.module.scss';


.primaryButton {
    --button-border: var(--primary-button-color, 1px solid #{$dark-teal});
    --button-color: var(--primary-button-color, #{$dark-teal});
    --button-text-color: var(--primary-button-text-color, #{$white});

    &:hover{
        --button-border: var(--primary-button-border, 1px solid #{$extra-dark-teal});
        --button-color: var(--primary-button-color, #{$extra-dark-teal});
        --button-text-color: var(--primary-button-text-color, #{$white});
    }

    &:active{
        --button-border: var(--primary-button-border, 1px solid #{$medium-light-teal});
        --button-color: var(--primary-button-color, #{$medium-light-teal});
        --button-text-color: var(--primary-button-text-color, #{$white});
    }

    &:disabled {
        --button-border: var(--primary-button-border, 1px solid #{$light-grey});
        --button-color: var(--primary-button-color, #{$light-grey});
        --button-text-color: var(--primary-button-text-color, #{$white});
      }

  }

  .secondaryButton {
    --button-border: var(--secondary-button-color, 1px solid #{$dark-teal});
    --button-color: var(--secondary-button-color, transparent);
    --button-text-color: var(--secondary-button-text-color, #{$dark-teal});

    &:hover{
        --button-border: var(--secondary-button-color, 1px solid #{$dark-teal});
        --button-color: var(--secondary-button-color, #{$extra-light-teal});
        --button-text-color: var(--secondary-button-text-color, #{$dark-teal});
    }

    &:active{
        --button-border: var(--secondary-button-color, 1px solid #{$dark-teal});
        --button-color: var(--secondary-button-color, #{$light-teal});
        --button-text-color: var(--secondary-button-text-color, #{$dark-teal});
    }

    &:disabled {
        --button-border: var(--secondary-button-color, 1px solid #{$light-grey});
        --button-color: var(--secondary-button-color, transparent);
        --button-text-color: var(--secondary-button-text-color, #{$light-grey});
      }

  }

  .tertiaryButton {
    --button-border: var(--secondary-button-color, none);
    --button-color: var(--secondary-button-color, transparent);
    --button-text-color: var(--secondary-button-text-color, #{$dark-teal});

    &:hover{
        --button-border: var(--secondary-button-color, none);
        --button-color: var(--secondary-button-color, #{$extra-light-teal});
        --button-text-color: var(--secondary-button-text-color, #{$dark-teal});
    }

    &:active{
        --button-border: var(--secondary-button-color, none);
        --button-color: var(--secondary-button-color, #{$light-teal});
        --button-text-color: var(--secondary-button-text-color, #{$dark-teal});
    }

    &:disabled {
        --button-border: var(--secondary-button-color, none);
        --button-color: var(--secondary-button-color, transparent);
        --button-text-color: var(--secondary-button-text-color, #{$grey});
      }

  }
  
  .button {
    border: var(--button-border);
    background-color: var(--button-color);
    color: var(--button-text-color);
    border-radius: 4px;
    height: 40px;
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    font-family: Poppins;
    align-items: center;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;

    &:active,
    &:focus {
      outline: none;
    }

    &:disabled {
    cursor: default;
    }
  }