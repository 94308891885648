@import 'src/styles/common.module';


.supportModal{

    display: flex;
    flex-direction: column;
    gap: 20px;

    .header{
        display: flex;
        align-items: center;
        gap: 20px;
        color: $dark-teal;
        svg{
            height: 40px;
            width: 40px;
        }
    }

    .content{
        display: flex;
        flex-direction: column;
        gap: 10px;

        .contactOptions{
            display: flex;
            flex-direction: column;
            gap: 20px;

            svg{
                margin-left: 12px;
            }
        }
    }

    .labelColor{
        color: $dark-teal;
    }

    .banner{
        display: flex;
        flex-direction: column;
        background-color: $extra-light-grey;
        border-radius: 8px;
        padding: 12px;
        gap: 10px;
        .infoItems{
            display: flex;

            .infoItem{
                display: flex;
                min-width: 250px;
                gap: 8px;
                svg{
                    color: $dark-teal;
                }
        
                .infoItemsFields{
                    .name{
                        color: $grey-60;
                    }
                }
        
            }
        }
    }

    .form{
        display: flex;
        flex-direction: column;
        gap: 16px;

        .textarea{
            height: 300px;
        }
        
    }

    .success{
        display: flex;
        gap: 20px;
        .icon{
            color: $green;
        }
        .message{
            color: $dark-grey;
        }
    }

    .footer{
        display: flex;
        flex-direction: column;
        gap: 20px;

        button{
            align-self: flex-end;
        }
    }
    
    

}