@import 'src/styles/common.module';

.select {
    display: block;
    min-width: 150px;
    border-radius: 4px;
}

.selectContainer {
    div{
        color:  $dark-teal;
        background-color: #FFF;
        font-size: 14px;
    }

}

.selectFooter{
    border-top: 1px solid $dark-teal;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    user-select: none;

    position: sticky;
    width: 100%;
    bottom: 0;
    background-color: white;
    
    .selectAll{
        color: $dark-teal;
        cursor: pointer;
    }

    .clear{
        cursor: pointer;
    }
}

.list{
     li { 
        font-size: 14px !important;
     }
     font-family: $font-family;
}