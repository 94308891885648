.leaflet-touch .leaflet-bar {
    border: none !important;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3) !important;
    border-radius: 8px !important;
    overflow: hidden;
}
.leaflet-control-zoom,
.leaflet-control {
    margin-top: 16px !important;
    margin-right: 16px !important;
    border-radius: 8px !important;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
}

.leaflet-control-zoom a:link,
.leaflet-control-zoom a:visited {
    color: #999999;
}
.leaflet-popup-content {
    width: auto !important;
    white-space: nowrap;
}

/* Workaround to display the scale on the map */
.leaflet-control-attribution .gmnoscreen+button.gm-style-cc{
    display: block !important;
}

.leaflet-top, .leaflet-bottom {
    pointer-events: none;
    z-index: 500 !important;

    > * {
        pointer-events: all !important;
    }
}
.leaflet-top.leaflet-left, .leaflet-bottom.leaflet-right{
    font-family: 'Poppins' !important;
}

.leaflet-top.leaflet-left {
    padding-left: 16px;
    display: grid;
    grid-template-rows:  minmax(123px, max-content) max-content 1fr min-content;
    grid-column: 4;
    height: 100%;
    align-items: start; 
    justify-items: start;  
}

.gmnoprint{
    z-index: 100!important;
}