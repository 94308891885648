@import 'src/styles/common.module';

.lozenges{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;

}

.lozenge{

    border-radius: 31px;
    padding: 5px 20px;
    color: $dark-teal;
    border: 1px solid $dark-teal;
    cursor: pointer;
    line-height: 16px;
    display: flex;
 
    &:hover{
        background-color: $medium-light-teal;
    }

    &Selected{
        background-color: $dark-teal;
        color: $white;

        &:hover{
            background-color: $dark-teal;
        }

    }
}

