@import 'src/styles/common.module';

.userProjectForm {

    display: flex;
    flex-direction: column;

    .stepIndicator {
        color: $dark-grey;
        font-size: 14px;
    }

    .section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;

    }

    .sectionContainer>*:not(:last-child) {
        border-bottom: 1px solid $medium-light-grey;
        padding-bottom: 10px;
    }

    .sectionHeading {
        color: $black;
        font-size: 14px;
    }

    .userContact {
        font-size: 14px !important;
    }
}