@import 'src/styles/common.module';

.metricViewContainer{
    display: flex;
    flex-direction: row;
    
    .textContent{
        margin-left:10px;
        font-weight: $bold;
        font-size: 12px;

        > div{
            color: $grey;
            font-weight: $normal;
            font-size: 10px;
        }
    }  
    
    @media only screen and (max-width: 919px) {
        flex-direction: column;
    }

}
