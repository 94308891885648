@import 'src/styles/common.module';


.sidebarContainer {
    padding: 20px;

    .titleContainer {
        display: flex;
        justify-content: space-between;

        margin-bottom: 20px;

        .title {
            font-size: 17px;
            color: $dark-teal;
        }
    }

    .contentContainer {
        display: flex;
        flex-direction: column;

        .contentTitleContainer {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;

            hr {
                background-color: var(--background-color, $dark-teal);
                width: 5px;
                height: 100%;
                margin-right: 10px;
                border: 'none';
            }
        }

        span {
            flex: 1
        }

        .content {
            margin-bottom: 20px;
        }

        table {
            width: 100%;
            font-size: 12px;

            th,
            td {
                text-align: left;
                padding: 8px;
                align-items: center;
            }

            th {
                align-items: left;
                color: $dark-teal;
                font-weight: $normal;
            }


        }
    }
}