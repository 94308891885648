@import 'src/styles/common.module';


.reviewAndSubmit{

    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-bottom: 50px;

    .section{

        display: flex;
        flex-direction: column;

        .sectionTitle{
            font-size: 17px;
            color: $black;
            line-height: 20.40px;
            padding-bottom: 10px;
            margin-bottom: 20px;
            border-bottom: 1px solid $light-grey;
            display: flex;
            justify-content: space-between;

        }

        .sectionItems{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 80px;

            .sectionItem{
                display: flex;
                flex-direction: column;
                gap: 10px;
                .label{
                    height: 32px;
                    color: $dark-grey;
                    display: flex;
                    align-items: center;
                }

                .value{
                    color: $dark-teal;
                }
            }

        }

        .sectionItems + .sectionItems{
            margin-top: 32px;
        }
    }
}

.customerContact{
    border: 1px solid $dark-teal;
    border-radius: 4px;
    padding: 9px;
    display: grid;
    row-gap: 10px;
    grid-template-columns: 30px 1fr;
    grid-template-areas: 'icon contact'
    'empty role';
    width: 350px;

    .userIcon{
        grid-area: icon;
        display: flex;
        color: $dark-teal;
    }

    .contactDetails{
        grid-area: contact;
        display: flex;
        flex-direction: column;

        &Name{
            font-weight: $bold;
        }
    }

    .role{
        grid-area: role;
        color: $dark-teal;
    }
}


.sampleGroups{
    display: flex;
    gap: 10px;
}

.sampleGroupTag{
    border: 1px solid $dark-teal;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: $white;

    &Color{
        border-radius: 50%;
        height: 25px;
        width: 25px;
    }

    &Value{
        font-size: 14px;
        color: $dark-teal;
    }
    
}


.habitatAssayTypes{
    display: flex;
    gap: 10px;
}

.habitatAssayTypeTag{
    border: 1px solid $dark-teal;
    border-radius: 8px;
    padding: 5px 8px;
    display: inline-grid;
    grid-template-columns: 1fr min-content;
    gap: 10px;
    align-items: center;
    background-color: $white;

    &Value{
        font-size: 14px;
        color: $dark-grey;
        display: flex;
        flex-direction: column;
        color: $dark-teal;

        .assayName{
            font-weight: $bold;
        }
    }
    
}

.samplingEvents{

    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .samplingEvent{
        border: 1px solid $dark-teal;
        border-radius: 8px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-right: 40px;

        .eventNumber{
            font-size: 14px;
            color: $black;
            display: flex;
            align-items: center;
        }
    
        .eventName{
            color: $dark-teal;
            font-size: 17px;
        }

        .datesTitle{
            color: $dark-grey;

        }
        .dates{
            display: flex;
            gap: 20px;

            .dateLabel{
                color: $dark-grey;
            }
            .dateValue{
                color: $dark-teal;
            }
        }

    }
   

}


.successModal{

    width: 357px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    .successTitle{
        font-size: 29px;
        color: $dark-grey;
    }
    .successContent{
        display: flex;
        align-items: center;
        gap: 10px;
        .successTickIcon{
            color: $green;
        }

    }

    .backButton{
        width: 100%;
    }

}

.bottomNavigation{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;

}


.samplesPerTestType{

    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    &Item{
        position: relative;
        display: grid;
        column-gap: 10px;
        grid-template-areas: 'count habitatName'
            'count assayName';
        color: $dark-teal;

        .count{
            grid-area: count;
            font-size: 29px;
        }

        .habitatName{
            grid-area: habitatName;
        }

        .assayName{
            grid-area: assayName;
            font-weight: $bold;
        }

        &:not(:last-of-type)::after{
            content: '';
            position: absolute;
            width: 1px;
            background-color: $dark-teal;
            bottom: 15%;
            top: 15%;
            right: -24px;
        }
    }
}

.samplingEventsNote {
    display: flex;
    flex-direction: column;
   
    &Title{
        font-size: 17px;
        color: $black;
    }

    &Text{
        font-size: 14px;
        color: $dark-grey;
    }
}

.otherActionsSection {
    display: flex;
    gap: 50px;

    .updateInfo {
        margin-bottom: 30px;
        text-align: right;
      
        .header {
          color: $dark-grey;
        }
      
        .name {
          font-size: 20px;
        }
    }
}