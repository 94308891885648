@import 'src/styles/common.module';

.chegdTable{


    .top{
        display: flex;
        align-items: center;
        .title{
            margin-top: 10px;
            margin-left: 10px;
            color: $dark-teal;
            font-weight: $normal;
            font-size: 24px;
        }

        .questionButton {
            margin-bottom: -8px;
        }
    }

    table{
        width: 100%;
        thead{
            border-top: 1px solid $light-grey;
            border-bottom: 1px solid $light-grey;
            th{
                color: $black;
                font-weight: $mediumBold;
                font-size: 14px;
                padding: 16px 16px 16px 8px;
            }
            
        }
        tbody{
            tr:nth-of-type(odd){
                background-color: #E6E6E6;

            }
            
            tr:last-of-type{
                border-bottom: 1px solid $light-grey !important;
            }
        }
    }
}