@import 'src/styles/common.module';

.userManagementFilters{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .filters{
        display: flex;
        gap: 16px;
        align-items: flex-end;

        .fieldContainer{
            display: flex;
            flex-direction: column;
            label{
                color: $dark-teal;
            }

            .searchInputContainer {
                position: relative;
                .searchIcon {
                    top: 7px;
                    color: $dark-teal;
                    position: absolute;
                    left: 15px;
                }
    
                .searchInput {
                    padding-left: 45px;
                }
            }
        }
    }
    
}

.createUserButton, .buttonReset{
    display: flex;
    gap: 10px;
    font-size: 14px;
}
.createUserButton{
    margin-top: 20px;
    margin-left: 10px;
}