@import 'src/styles/common.module';


.title{
    color: $dark-teal;
    font-size: 20px;
    margin-bottom: 16px;
}

.label{
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: $dark-teal;
}

.labelTitle{
    font-weight: $light;
}


.detailedContentbody{
    --modal-body-height: 90vh;
    --modal-body-width: 80vw;
    --modal-body-max-width: 1200px;
}

.detailedPopupLabel{
    color: $dark-teal;
    display: flex;
    justify-content: flex-end;
}