@import 'src/styles/common.module';

.noDataContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .noDataImage {
        width: 25vh;
        max-width: 500px; /* adjust as needed */
        height: 25vh;
        display: block;
        margin-bottom: 50px;
    }

    .noDataTextContainer {
        margin-top: 10px;
        text-align: center;
        color:$grey;
        font-size: 20px;
        font-weight: $normal;
    }
}

