@import 'src/styles/common.module';


.habitatInsightsUpload {
    display: flex;
    flex-direction: column;   
    gap: 25px;
}

.otherActions {
    display: flex;
    gap: 10px;

    button {
        gap: 10px;
    }
}

.formView {
    width: 720px;
    display: flex;
    flex-direction: column;

    &FullWidth {
        width: 100%;
    }
}

.formViewTitle {
    color: $black;
    font-size: 17px;
    border-bottom: 1px solid $dark-grey;
    margin-bottom: 40px;
}