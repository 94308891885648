@import 'src/styles/common.module';

.conditionalField{
    svg{
        color: #CF8515;
    }
}
.conditionalFieldNoWarning{
   margin: -20px 0 0 -15px;
   div {
    background-Color: 'transparent';
    color: $grey;
   }
}


