@import 'src/styles/common.module';

.chartContainer{
   border-top: 1px solid #ccc;
}
.insights {
  flex-grow: 1;
  box-shadow: '0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08)';
  border-radius: '10px';
  margin-top: 2rem;;
}
.chartTitle {
  display: flex;
  align-items: baseline;
  .titleValue {
      margin-bottom: 0.5rem;
      color: $dark-teal;
      font-size: 24px;
      font-weight: $normal;
  }
}

.container{
  display: grid;
  grid-template-columns: 1fr 1fr; /* Set two equal-sized columns */
  grid-gap: 20px; 
}
  .breadcrumbs{
    grid-column-start: 1;
   
  }
  .filters{
    display: flex;
    flex-direction:column;
    justify-self: end;
    margin-top: -15px;
    
    .filterLabel{
      color: $dark-teal;
    }
  }
