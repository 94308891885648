@import 'src/styles/common.module';
    .container {
        margin-left: 0;
        margin-top: 1rem;
        display: 'flex';
        .leftCol {
            font-style: normal;
            color: #076769;
            align-self: center;
            padding: 0 4rem;
        }
        .title {
            font-size: 50px;
            font-weight: 700;
            margin-bottom: 2rem;
        }
        .subTitle {
            font-weight: 700;
            font-size: 42px;
            margin-bottom: 2rem;
        }
        .description { font-weight: 400; font-size: 29px }
    }

