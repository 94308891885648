@import 'src/styles/common.module';

.noDataMessage {
    padding: 5rem 10rem;
}

.container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .card {
    flex-basis: calc(33.33% - 16px); /* Three columns for larger screens by default */
    padding: 16px;
    border: 1px solid $white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: $white;
    text-align: left;
    height: 152px;
    display: flex; 
    flex-direction: column; 
    justify-content: center;
    cursor: pointer;
    
    &:hover {
      background-color: $medium-light-grey;
      border-color:  $medium-light-grey;
    }

    &:active {
      background-color: $light-grey;
      border-color:  $light-grey;
    }
  }
  
  .cardTitle {
    font-size: 24px;
    font-weight: $normal;
    margin-bottom: 4px;
    color: $dark-teal;

  }

  .cardSubTitle {
    font-size: 12px;
    font-weight: $normal;
    margin-bottom: 8px;
    color: $dark-grey;
  }
  
  @media only screen and (min-width: 680px) and (max-width: 1199px) {
    .card {
      flex-basis: calc(50% - 16px); 
    }
  }
  

  @media only screen and (max-width: 679px) {
    .card {
      flex-basis: calc(100% - 16px); 
    }
  }

  
  
  
