@import 'src/styles/common.module';

.boxPlot{
    position: relative;
    svg{
        font-weight: $light;
    }
}

.legends{
    padding-left: 30px;
    display: flex;
    .legend{
        display: inline-block;
        width: 200px;
        padding-left: 50px;
        box-sizing: border-box;
        margin-left: 65px;
        text-align: center;
        font-weight: $light;
    }

    .legend+.legend{
        margin-left: 0px;
    }
}

.boxTooltip{

    width: 180px;
    background-color: $white;
    box-shadow: 0px 0px 15px 2px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 8px;
    padding: 16px;


    &::before{
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left:8px solid $white;
        right:-8px;

        top: calc(50% - 8px);

    }


    &Title{
        color: $dark-teal;
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 10px;
    }

    &Row{
        display: grid;
        grid-template-columns: 40px auto;
        gap: 10px;
        font-size: 14px;
        
        span{
            color: $red;
        }

        span+span{
            color: $dark-grey;
        }
    }
}


.pointTooltip{

    min-width: 150px;
    background-color: $white;
    box-shadow: 0px 0px 15px 2px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 8px;
    padding: 16px;

    &::before{
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right:8px solid $white;
        left:-8px;

        top: calc(50% - 8px);
    }

    &SampleId{
        color: $dark-teal;
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 10px;
    }

    &Value{
        color: $dark-grey;
        font-size: 24px;
        line-height: 28.8px;
    }

    &Metric{
        color: $dark-grey;
        font-size: 12px;
        line-height: 14px;
    }

}