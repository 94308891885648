@import "src/styles/common.module";

.pages {
  border-bottom: 1px solid grey;
}
.pages:first-child {
  margin-top: -40px;
}
.pages:last-child {
  border-bottom: none;
}

.termsOfUse, .termsOfUseDeclined {
    padding: 56px 32px;
    width: 878px;
    height: 780px;
    background: $white;
    border: 1px solid $grey;
}

.termsOfUseDeclined{
  height: 258px;
  padding: 72px 32px;
}
  
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white;
    border: 1px solid $grey;
    padding: 3rem 1rem;
    width: 878px;
  }
  
  .header {
    align-self: stretch;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    display: flex;
  }
  
  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: $normal;
    line-height: 120%;
    color: $dark-grey;
    white-space: nowrap;
  }
  
  .downloadBtn {
    display: flex;
    height: 40px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 4px;
  }
  
  .contentBox {
    height: 483px;
    align-self: stretch;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 24px;
    margin-bottom: 40px;
    border: var(--primary-button-color, 1px solid #{$dark-teal});
  }
  
  .articleContent {
    max-height: 483px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
  }
  
  .footer {
    text-align: center;
  }
  
  .btnGroup {
    align-content: stretch;
    display: flex;
    gap: 20px;
    margin-top: 20px;
    
    button{ 
      flex: 1;
    }

  }
  
.errorMessage{
  margin-top: 20px;
}