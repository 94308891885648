@import 'src/styles/common.module';

.container {
    display: flex;
    background-color: $white;
    padding: 20px;
    gap: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0.4, 0.4, 0.4, 0.2);
    
    @media only screen and (max-width: 1039px) {
        flex-direction: column;
    }

    .imageContainer {
        flex-basis: 30%;
        position: relative;
        display: flex;
        img {
            width: 100%;
            height: auto;
        }
        order: 1;

        @media only screen and (min-width: 1200px) {
            width: 100%;
            order: 2;
        }

        @media only screen and (min-width: 848px) and (max-width: 1199px) {
            display: none;
        }

        @media only screen and (max-width: 847px) {
            width: 100%;
        }
    }
    .contentBucket {
        order: 2;
        @media only screen and (min-width: 1200px) {
            order: 1;
        }

        @media only screen and (min-width: 1536px) {
            flex-basis: 70%;
        }
        .titleContainer {
            color: $black;
            margin-bottom: 20px;
            .title {
                font-weight: 400;
                font-size: 24px;
            }
            .subTitle {
                font-size: 14px;
            }
        }

        .metricContainer {
            flex-basis: 60%;
            display: flex;
            align-items: flex-start;
            justify-items: center;
            justify-content: space-between;
            gap: 10px;

            .metric {
                display: flex;
                flex-direction: column;
                width: 27%;
                min-width: 210px;
                justify-content: space-around;
                position: relative;
               
                @media only screen and (min-width: 1200px) and (max-width: 1425px) {
                    max-width: 30%;
                }
            }
            @media only screen and (min-width: 1200px) {
                background: none !important;
                justify-content: space-between;
            }
            @media only screen and (min-width: 848px) and (max-width: 1199px) {
                display: flex;
                justify-content: flex-start;
                background-position: right !important;
                background-size: 12% 100% !important;
                width: 100%;
            }
            @media only screen and (max-width: 847px) {
                width: 100%;
                background: none !important;
                flex-direction: column;
                align-items: center;
            }
        }

        .header {
            display: flex;

            @media only screen and (min-width: 920px) and (max-width: 1199px) {
                align-items: center;
            }

            @media only screen and (max-width: 919px) {
                align-items: center;
            }

            .title {
                font-size: 17px;
                color: $dark-teal;
                font-weight: $normal;
                display: flex;
                line-height: 17px;
                min-height: 34px;
                align-items: center;
                margin-bottom: 20px;
            }

            .helpButton {
                margin-top: -20px;
                @media only screen and (min-width: 1425px) {
                    margin-top: 0;
                }

                @media only screen and (min-width: 1200px) and (max-width: 1425px) {
                    margin-top: 0;
                }
            }
        }
    }
    .mediumBold {
        font-weight: $mediumBold;
    }

    .score {
        grid-area: score;
        font-size: 50px;
        font-weight: 400;
        color:  $primary-navy;
        line-height: 50px;
    }

    .explainer{
        font-size: 16px;
    }
}
