@import 'src/styles/common.module';


.questionButton{
    color: $dark-teal;
    display: inline-flex;
    align-items: center;
    svg{
        height: 16px;
        width: 16px;
        color: $dark-teal;
    }
}