@import 'src/styles/common.module';

.table {
    --_table-background: var(--table-background, transparent);
    border-collapse: collapse;
    background-color: var(--_table-background);
}

.tableWithStickyHeader thead tr {
    position: sticky;
    top: 0;
    background-color: var(--table-head-background, var(--_table-background));
}

.table th {
    color: $dark-teal;

    font-size: var(--table-head-font-size, 12px);
    font-weight: var(--table-head-font-weight, #{$normal});
    box-shadow: inset 0 -1px 0 var(--table-head-border-color, transparent);
    background-clip: padding-box;
}

.table th,
.table td {
    padding: 8px;
    text-align: left;
}

.table td {
    vertical-align: middle;
    font-weight: $light;
    font-size: 14px;
}

.table tr+tr:not(.rowFooter) {
    border-top: 1px solid var(--table-row-border-color, transparent);
}

.table tr:last-of-type:not(thead tr) {
    border-bottom: 1px solid var(--table-row-border-color, transparent);
}

.rowFooter td {
    padding-top: 0;
}