@import 'src/styles/common.module';

.expandableSection {
  position: relative;
  overflow: auto;
}

.chevron {
  position: absolute;
  top: 5px;
  right: 5px;
}


.toggle {
  position: absolute;
  top: -3px;
  right: 0px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}