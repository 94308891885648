@import 'src/styles/common.module';

.tabsContainer {
  display: flex;
  overflow-y: hidden;
  scrollbar-width: none;
  align-items: center;
  gap: 10px;
}

.tab {
  padding: 3px;
  cursor: pointer;
  white-space: nowrap;
}

.disabled {
  color: $dark-grey;
  cursor: default;
}

.selected {
  color: $black;
  color: $dark-teal;
  position: relative;
  cursor: default;
}