@import 'src/styles/common.module';


.stepIndicator{
    display: flex;
    gap: 20px;

    .step{
        display: flex;
        gap: 10px;
        align-items: center;

        &Bullet{
            width: 24px;
            height: 24px;
            padding-top: 1px;
            background-color: $medium-light-grey;
            border: 1px solid $dark-teal;
            border-radius: 50%;
            text-align: center;
            svg{
                margin-top: -2px;
                margin-left: -2px;
            }
        }

        &Text{
            color: $dark-teal;
            font-size: 16px;
            line-height: 20px;
        }

        &Connector{
            width: 50px;
            height: 2px;
            background-color: $grey;
        }

        &Active {
            .stepText{
                font-weight: $bold;
            }

            .stepBullet{
                background-color: $dark-teal;
                color: $white;
            }
        }

        &Completed {
            .stepBullet{
                background-color: $dark-teal;
                color: $white;
            }
        }
    }
}