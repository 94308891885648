.filters {

    display: flex;
    column-gap: 23px;
    justify-content: flex-end;
    align-items: flex-end;

    .filterGroup {
        display: flex;
        column-gap: 32px;
        padding-left: 32px;
        align-items: flex-end;
    }

    .selectGroup {
        display: flex;
        column-gap: 20px;
    }

    .selectInput {
        display: flex;
        flex-direction: column;
    }

    .labelGroup {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}