@import 'src/styles/common.module';


.addSpecies{
    display: grid;
    grid-template-columns: 1fr min-content;
    gap: 10px;
}


.speciesOfInterestList{
    display: flex;
    gap: 10px;
}

.speciesOfInterest{
    border: 1px solid $dark-grey;
    border-radius: 8px;
    background-color: $white;
    padding: 4px 8px;
    display: inline-grid;
    grid-template-columns: 1fr min-content;
    gap: 10px;
    margin-top: 20px;
    align-items: center;

    .speciesOfInterestNames{
        display: flex;
        flex-direction: column;
        
        .scientificName{
            font-size: 14px;
            color: $dark-teal;
        }

        .commonName{
            font-size: 14px;
            font-style: italic;
            color: $dark-grey;
        }
    }

    svg{
        fill: $dark-teal;
        cursor: pointer;
    }
    
}