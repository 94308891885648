@import 'src/styles/common.module';

.tableWrapper{
    height: 100%;
    overflow: auto;
}
.dataTable {
    --table-height: 100%;
    flex: 1 1 auto;
    color: #262d38;

    th{
        div{
            max-width: 150px;
        }
    }

}
.downloadButton{
    font-size: 14px;
    font-weight: $bold;
}
