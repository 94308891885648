@import 'src/styles/common.module';

.metricsContainer{
    height: 100%;
    overflow: auto; 
    padding-bottom: 20px;
}


.metrics{
    grid-area: charts;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .chart{
        width: calc(50% - 10px);
    }

    .gridContainer {
        display: flex;
        gap:20px;
    }
    .gridColumn {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 0px;
        
      }

    
    @media only screen and (max-width: 1024px) {
        margin-bottom: 3rem;
        width: 100%;
        .chart {
            width: 100%;
            margin-bottom: 1rem;
        }
    }
}

.iucnMetrics{
    width:100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 6px -4px rgb(24 39 75 / 12%), 0px 8px 8px -4px rgb(24 39 75 / 8%);
    background-color: $white;
    margin-bottom: 20px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0.4, 0.4, 0.4, 0.2);
    border-radius: 8px;
}

.fishMetricAndSpeciesHighlight{
    display: flex;
    gap: 20px;
}

.fishIndex{
    flex-grow: 1;
    flex-basis: 0;
    border-radius: 10px;
    box-shadow: 0px 4px 6px -4px rgb(24 39 75 / 12%), 0px 8px 8px -4px rgb(24 39 75 / 8%);
    background-color: $white;
    margin-bottom: 20px;
    .backDrop{
        position: relative;
        
        img{
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }
}


.timeseriesCard{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 10px;
    width: calc(50% - 10px);
    background-color: white;
    padding: 16px 16px 24px;
}