@import 'src/styles/common.module';

.filters{
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    padding: 32px 24px;
    background-color: $medium-light-grey;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    white-space: nowrap;
    
    .selectContainer{
        margin-bottom: 20px;

        .select{
            width: 100%;
        }

        
        .color-0fc0c7{
            >div{
                border-left: 10px solid #0fc0c7;
            }
        }
        .color-bc4e7c{
            >div{
                border-left: 10px solid #bc4e7c;
            }
        }
        .color-e3a52d{
            >div{
                border-left: 10px solid #e3a52d;
            }
        }
        .color-386EB9{
            >div{
                border-left: 10px solid #386EB9;
            }
        }
        .color-e25f18{
            >div{
                border-left: 10px solid #e25f18;
            }
        }
        .color-8b79d4{
            >div{
                border-left: 10px solid #8b79d4;
            }
        }
        .color-a4e8e9{
            >div{
                border-left: 10px solid #a4e8e9;
            }
        }
        .color-e7de41{
            >div{
                border-left: 10px solid #e7de41;
            }
        }
        .color-67af20{
            >div{
                border-left: 10px solid #67af20;
            }
        }
        .color-a71b1{
            >div{
                border-left: 10px solid #a71b1b;
            }
        }


        
    }

    .selectTitle{
        margin: 15px 0 4px;
        color: $dark-teal;
    }

    .dataSelectors{
        display: flex;
        gap: 10px;
        color: $dark-teal;
        .fromDate{
            display: flex;
            align-items: center;
            column-gap: 3px;
        }

        .toDate{
            display: flex;
            align-items: center;
            column-gap: 3px;
        }
    }
}
