@import 'src/styles/common.module';
.communityComparisonChart{
    position: relative;
    svg{
        height: 400px;
        width: 100%;
    }
}

.pointTooltip{

    min-width: 250px;
    background-color: $white;
    box-shadow: 0px 0px 15px 2px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 8px;
    padding: 16px;
    user-select: none;
    position: absolute;
    visibility: hidden;


    &::before{
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;
        border-left: 8px solid transparent;
        border-top: 8px solid $white;
        border-right:8px solid transparent;
        left: calc(50% - 2.5px);
        bottom: -6px;
        pointer-events: none;
    }

    &Left {
        &::before{
            border-bottom: 8px solid transparent;
            border-right: 8px solid $white;
            border-top:8px solid transparent;
            left: -15px;
            top: calc(50% - 3px);
        }
    }

    .topContent{
        display: grid;
        grid-template-areas: 'sampleId dateLabel'
            'sampleId dateValue';
        column-gap: 10px;
        border-bottom: 1px solid $light-grey;
        padding-bottom: 10px;
        margin-bottom: 10px;

        .sampleId{
            grid-area: sampleId;
            color: $dark-teal;
            font-size: 29px;
        }

        .dateLabel{
            grid-area: dateLabel;
            color: $dark-grey;
            white-space: nowrap;
        }

        .dateValue{
            grid-area: dateValue;
            font-weight: $bold;
            color: $dark-grey;
            text-align: right;
            white-space: nowrap;
        }

    }

    &Metric{
        color: $dark-grey;
        font-size: 12px;
        line-height: 14px;

        &Label{
            color: $dark-teal;
            font-size: 17px;
            margin-bottom: 15px;
        }

        &Value{
            display: flex;
            flex-direction: row;
            align-content: baseline;
            gap: 10px;
            color: $dark-grey;
            font-size: 29px;

            span{
                font-size: 14px;
            }
        }


    }

}