@import 'src/styles/common.module';


.aside{
    overflow-y: auto;
    padding: 30px;

    .bottomContentPlaceholder{
        text-align: center;
        padding: 30px;
        color:$dark-grey;
    }

}

.sectionSeparator{
    margin: 20px -30px;
    width: calc(100% + 60px);
    height: 1px;
    background-color: $medium-light-grey;
}

.sampleInfo{

    display: grid;
    width: 300px;
    gap: 10px;
    grid-template-areas: 'sampleId dateReceived'
        'metric metric';


    .sampleId{
        grid-area: sampleId;
        font-size: 25px;
        color: $dark-teal;
        max-width: 200px;
        overflow-x: auto;

    }
    .dateReceived{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .dateReceivedLabel{
            grid-area: dateReceivedLabel;
        }
    
        .dateReceivedValue{
            grid-area: dateReceived;
            font-weight: $bold;
        }
    }
    
    .metric{
        grid-area: metric;
        display: grid;

        grid-template-areas: 'title title'
            'value label';
            grid-template-columns: min-content auto;
        column-gap: 10px;

        .metricTitle{
            grid-area: title;
            color: $dark-teal;
            font-size: 20px;
        }
        .metricValue{
            grid-area: value;
            font-size: 25px;
            font-weight: $bold;
        }
        .metricValueLabel{
            grid-area: label;
            align-self: center;
        }
    }

    
}

.sampleGroupStats{

    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;

    .textAboveBar{
        font-size: 17px;
    }

}

.textHighlight{
    color: $teal;
}


.cube{
    background-color: #076769;
    height: 18px;
    width: 18px;
    display: inline-block;
    margin-left: 15px;
    margin-bottom: -5px;
}

.speciesListTable{
    .speciesName{
        font-style: italic;
    }
}