@import "src/styles/common.module";

.title{
    font-weight: normal;
    font-size: 36px;
    display: flex;
    color: $grey;
    margin-top: 20px;
    align-self: start;
}

.subTitle{
    font-size: 20px;
    line-height: 23px;
    color: $black;
    margin-top: 5px;
    align-self: start;
    color: $dark-grey;
}

.formContainer{
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.signinForm, .forgotPasswordForm{
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.signinForm{
    margin-top: 40px;
}


.passwordRules{
    color: $grey;
    ul{
        font-size: 12px;
        line-height: 14.40px;
        margin: 5px 0 0 22px;
    }
}

.loginButton{
    button{
        font-size: 20px;
        width: 100%;
    }
}

.forgotPasswordLink, .resendLink{
    font-size: 14px;
    color: $dark-teal;
    cursor: pointer;
    text-decoration: underline;
    align-self: flex-start;
}

.errorIcon{
    width: 20px;
    height: 20px;
}


.errorMessage{
    margin-top: 10px;
}

.resendLink{
    align-self: flex-end;
}

.forgotPasswordContainer{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;

    .lockIconContainer{
        background-color: $dark-teal;
        border-radius: 50%;
        width: 40px;
        height: 40px;

        line-height: 1;
        svg{
            color: $white;
            margin: 8px;
        }
    }

    .forgotPasswordTitle{
        font-size: 24px;
        font-weight: $bold;
    }


    .loginLink{
        cursor: pointer;
        color: $dark-teal;
        cursor: pointer;
        text-decoration: underline;
        justify-content: center;
    }
}


.phoneOrEmail{
    font-size: 14px;
    margin-top: 10px;
}