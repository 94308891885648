@import 'src/styles/common.module';

.samplingEvent{
    padding: 15px 22px;
    border: 1px solid $dark-teal;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-bottom: 16px;

    .inputLabel{
        color: $dark-teal;
    }

    .expectedDates{
        color: $dark-grey;
    }

    .fromTo{
        color: $dark-teal;
    }

    &Title{
        font-size: 17px;
        display: flex;
        align-items: center;
    }

    &HasError{
        border-color: $red;
    }
}


.dateSelectors{
    display: flex;
    gap: 20px;

    .dateSelector{
        display: flex;
        gap: 10px;
        align-items: center;
    }
}

.samplingEventsNote {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    
    &Title{
        font-size: 17px;
        color: $black;
    }

    &Text{
        font-size: 14px;
        color: $dark-grey;
    }
}

.endYearError {
    margin-bottom: 40px;
}
