@import 'src/styles/common.module';
.errorBoundaryContainer {
    margin-left: 0;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    .left {
        font-style: normal;
        color: $dark-teal;
        align-self: center;
        padding: 0 4rem;

        .title {
            font-size: 50px;
            font-weight: 700;
            margin-bottom: 2rem;
        }
        .subTitle {
            font-weight: 700;
            font-size: 42px;
            margin-bottom: 2rem;
        }
        .solution { font-weight: 400; font-size: 29px }

         .buttonWrapper{
            margin-top: 20px;
         }
    }
    
}

