@import 'src/styles/common.module';

.addHabitatAssayType{
    display: grid;
    grid-template-columns: 1fr min-content;
    gap: 10px;
    position: relative;
}

.habitatAssayTypes{
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.habitatAssayTypeTag{
    border: 1px solid $dark-teal;
    border-radius: 8px;
    padding: 5px 8px;
    display: inline-grid;
    grid-template-columns: 1fr min-content;
    gap: 10px;
    align-items: center;
    background-color: $white;

    &Value{
        font-size: 14px;
        color: $dark-grey;
        display: flex;
        flex-direction: column;
        color: $dark-teal;

        .assayName{
            font-weight: $bold;
        }
    }

    svg{
        fill: $dark-teal;
        cursor: pointer;
    }
    
}