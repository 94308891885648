@import 'src/styles/common.module.scss';

.pagination {
  padding-left: 30px;
  display: flex;
  white-space: nowrap;
  align-items: center;
}

.inputWrapper {
  width: 50px;
  margin-right: 5px;
  display: inline-block;
}

.inputBox {
  height: 27px;
  text-align: center;
}

.chevron {
  padding: 5px 5px;
  margin: 0 5px;
  border-radius: 5px;
  svg{
    height: 30px;
    width: 30px;
  }
  
  --chevron-fill: #{$grey};

  &:hover{
    background-color: $medium-light-grey;
  }

  &:active{
    background-color: $light-grey;
  }
}
