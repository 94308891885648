@import 'src/styles/common.module';


.markerTooltip {
  font-family: 'Poppins';
  display: flex;
  flex-direction: column;
  color: $dark-grey;
  font-size: 16px;

  .topContent {
    display: flex;
    flex-direction: column;


    .sampleId {
      display: flex;
      flex-direction: row;
      font-size: 24px;
      color: $dark-teal;
    }

    .location {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      margin-top: 5px;
      color: $grey;
      align-items: center;
      gap: 8px;
    }
  }

  .divider {
    margin: 10px 0;
    height: 1px;
    display: block;
    background-color: $light-grey;
  }

  .bottomContent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 24px;

    .label {
      color: $grey;
    }

    .habitatAssayTypes {
      margin-top: 5px;
      display: flex;
      gap: 10px;
    }

    .habitatAssayTypeTag {
      border: 1px solid $dark-teal;
      border-radius: 8px;
      padding: 5px 8px;
      display: inline-grid;
      grid-template-columns: 1fr min-content;
      gap: 10px;
      align-items: center;
      background-color: $white;

      &Value {
        font-size: 14px;
        color: $dark-grey;
        display: flex;
        flex-direction: column;
        color: $dark-teal;

        .assayName {
          font-weight: $bold;
        }
      }

    }
  }
}