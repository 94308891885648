@import 'src/styles/common.module';

.title{
    font-size: 17px;
    color: $black;
}

.actions{
    display: flex;
    gap: 20px;
    justify-items: stretch;
    padding: 0 20px 20px !important;

    > button {
        flex: 1;
    }
}

