@import 'src/styles/common.module';

$chartBlockWidth: 100%;
.chartContainer {
    border-top: 1px solid #ccc;
    width: 100%;
}

.chartColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.chartHeader {
    display: flex;
    height: 60px;
    > div {
        width: $chartBlockWidth;
        display: flex;
        justify-content: center;
        color: $dark-teal;
        font-size: 20px;
        font-weight: $bold;
        padding-top: 10px;
        padding-left: 5px;
    }
}

.chartColumn:not(:last-of-type) {
    .chartHeader {
        > div {
            border-right: 1px solid $light-grey;
        }
    }
}

.chartBlockContainer {
    position: relative;
    .chartBlock {
        width: $chartBlockWidth;
        display: grid;
        grid-template-columns: 1fr 30px;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 0 30px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid white;
        cursor: pointer;

        .chartBlockLeft {
            position: relative;
        }

        .chartBlockName {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:hover:not(&Opaque) {
            background-color: $black !important;
            .chartBlockName,
            .chartBlockValue {
                color: white !important;
            }
        }

        &tax_kingdom {
            font-size: 16px;
        }

        &tax_species,
        &tax_genus {
            .chartBlockName {
                font-style: italic;
            }
        }

        &::after {
            border-left: 15px solid #ffffff60;
            content: '';
            position: absolute;
            left: 0;
            height: 100%;
        }

        &Opaque {
            opacity: 0.3;
            pointer-events: none;
        }
    }

    .blockPopup {
        position: absolute;
        top: calc(100% - 2px);
        z-index: 10;
        padding: 20px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.3);
        cursor: default;

        .topContent {
            min-width: 250px;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid $light-grey;
            display: grid;
            gap: 10px;
            grid-template:
                'scientificName speciesDetected'
                'commonName speciesDetected'
                'description description';

            .scientificName {
                color: $dark-teal;
                font-size: 24px;
                line-height: 120%;
                grid-area: scientificName;
            }

            .commonName {
                color: $dark-grey;
                font-style: italic;
                font-size: 17px;
                line-height: 120%;
                grid-area: commonName;
            }

            .speciesDetected {
                font-size: 14px;
                color: $dark-grey;
            }
        }

        .highlightBranch {
            color: $dark-teal;
            margin-bottom: 30px;
            user-select: none;
            cursor: pointer;
        }
        .hideOtherBranch {
            color: $dark-teal;
            user-select: none;
            cursor: pointer;
        }
    }

    .chartBlocktax_species {
        .blockPopup {
            left: -200px;
        }
    }
}

.selectContainer {
    div {
        padding-top: 2px;
        background-color: transparent;
        color: #076769;
    }
    & .Mui-focused,
    .MuiOutlinedInput-notchedOutline,
    fieldset {
        border: none;
    }
}

.chartFooter {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.colorScaleBucket {
    .colorScale {
        display: flex;
        flex-direction: row;
        span {
            height: 25px;
            width: 40px;
        }
    }
    .colorScaleDescription {
        line-height: 30px;
        display: flex;
        margin-bottom: 15px;
    }
    .colorScaleLabels {
        display: flex;
        justify-content: space-between;
    }
}

.topContent {
    margin-top: 10px;
    gap: 23px;
    display: flex;
    justify-content: space-between;
}
.bottomColorScaleBar {
    margin-top: 0.75rem;
    margin-bottom: 2rem;
}
