@import 'src/styles/common.module';

$highlightedUploadBackground: $extra-light-teal;
$uploadButtonHeight: 140px;
$uploadButtonWidth: 200px;

.upload {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: var(--drop-zone-height, #{$uploadButtonHeight});
  width: var(--drop-zone-width, 300px);
  background-color: var(--drop-zone-bg-color, #{$white});
  position: relative;
  border: 2px dashed $dark-teal;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  transition: color 0.4s, border-color 0.4s, background-color 0.4s;
}

// this will prevent dragenter and dragleave events emitted by children of the drop area
.upload > * {
  pointer-events: none;
}

.uploadDragOver {
  background-color: var(--drop-zone-bg-color, #{$highlightedUploadBackground});
}

.uploadDisabled {
  border: 1px dashed $grey;
  color: $grey;
  pointer-events: none;
}

.uploadDisabled .uploadIcon {
  fill: $grey;
}

.fileInput {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  height: 1px;
  width: 1px;
}

.defaultUpload:focus-within {
  background: 1px dashed $black;
}

.label {
  font-size: 12px;
  text-align: center;
}