@import 'src/styles/common.module';

.legendContainer{
    .legendLabel{
        color: $black;
        font-style: $normal;
        font-weight: $normal;
        font-size: 14px;
        line-height: 30px;
    }
    .hiddenLegendLabel{
        color: $light-grey;
        line-height: 30px;
    }
  
    .reset{
        margin-top: 10px;
        margin-left: 8px;
        color: $dark-teal;
    }
}
