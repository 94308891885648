@import 'src/styles/common.module';
.userTypeTag {
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    height: 24px;
    text-align: center;
    color: #FFFFFF;

    &Internal {
        border: 1px solid $medium-light-teal;
        background-color: $medium-light-teal;
    }

    &Customer {
        border: 1px solid $dark-blue;
        background-color: $dark-blue;
    }
    
}