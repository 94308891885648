@import 'src/styles/common.module';

.addSampleGroup{
    display: grid;
    grid-template-columns: 1fr min-content;
    gap: 10px;
    position: relative;
    .sampleGroupInput{
        padding-left: 45px;
    }
    .colorIndicator{
        display: block;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        position: absolute;
        left: 16px;
        top: 8px;
        background-color: var(--sample-group-input-area-indicator-color, black);
        z-index: 100;
    }
}

.sampleGroups{
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.sampleGroupTag{
    border: 1px solid $dark-teal;
    border-radius: 8px;
    padding: 8px;
    display: inline-grid;
    grid-template-columns: 20px 1fr min-content;
    gap: 10px;
    align-items: center;

    &Color{
        border-radius: 50%;
        height: 25px;
        width: 25px;
    }

    &Value{
        font-size: 14px;
        color: $dark-grey;
    }

    svg{
        fill: $dark-teal;
        cursor: pointer;
    }
    
}