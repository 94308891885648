@import 'src/styles/common.module';

.status{
    display: flex;
    gap: 5px;
    align-items: center;

    .statusIndicatorIcon{
        height: 12px;
        width: 12px;
        margin: 4px;
        border-radius: 50%;
        &success{
            background-color: $green;
        }

        &warning{
            background-color: $orange;
        }

        &error{
            background-color: $red;
        }

        &disabled{
            background-color: $grey;
        }
    }

    .statusIndicatorText{
        &success{
            color: $black;
        }

        &warning{
            color: $black;
        }

        &error{
            color: $grey;
        }

        &disabled{
            color: $black;
        }
    }
}