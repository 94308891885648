@import 'src/styles/common.module';

.videoHelp{

    position: fixed;
    bottom: 32px;
    right: 32px;
    height: 56px;
    min-width: 56px;
    border-radius: 56px;
    background-color: white;
    padding: 16px;
    overflow: hidden;
    box-shadow: 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    z-index: 1000;

    &Top{
        bottom: unset;
        top: 32px;
    }
    &Controller{
        display: flex;

        &Text{
            width: 0; 
            white-space: nowrap;
            overflow: hidden;
            transition: width linear 0.2s, margin-right linear 0.2s;
            color: $dark-teal;
            margin-right: 0px;
            font-weight: $mediumBold;

            .videoHelpController:hover & {
                overflow: auto;
                width: 105px; 
                display: block;
                margin-right: 10px;
            }
        }

        &Icon{
            background: white;
        }

    }

    &Open{
        z-index: 1003;
    }

    &Content{
        padding: 20px;
    }

}