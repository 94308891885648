@import 'src/styles/common.module';
.customerDetails {
    display: flex;
    padding: 12px 16px;
    border-radius: 8px;
    background: $medium-light-grey;
    gap: 8px;
    .icon {
        height: 32px;
        width: 32px;
        color: $dark-grey;
        margin-top: 4px;
    }

    .description {
        display: flex;
        flex-direction: column;
        font-weight: 400;

        .label{
            font-size: 17px;
            color: $dark-grey;
        }

        .name{
            font-size: 20px;
            color: $black;
        }
    }
}