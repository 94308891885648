@import 'src/styles/common.module';

.uploadWrapper{

    width: 720px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .top{
        display: flex;
        justify-content: space-between;

        .topLeft{
            display: grid;
            gap: 8px;
            grid-template-areas: 'icon title'
                'icon fileType';

            .uploadIcon{
                grid-area: icon;
                align-self: center;
                color: $dark-grey;
            }
            .uploadTitle{
                grid-area: title;
                font-size: 20px;
            }
            .uploadFileType{
                grid-area: fileType;
                color: $dark-grey;
            }
        }

        .topRight{
            display: flex;
            align-items: flex-end;
            
        }
    }

    .bottom{

        .uploadInput{
            --drop-zone-width: 100%;
        }
    }
}