.lozenge{
    
    display: inline-block;
    background-color: #0077cc;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    border-radius: 20px;
    padding: 5px 10px;

}
