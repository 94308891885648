@import 'src/styles/common.module';

.select {
    display: block;
    min-width: 100%;
    border-radius: 4px;

    label{
        font-size: 14px;
    }
}

.selectContainer {
    div{
        color: $dark-teal;
        background-color: var( --select-background-color, #FFF);
        font-size: 14px;
    }
}

.list{
    li{
        font-size: 14px !important;
    }
    font-family: $font-family;
}