@import 'src/styles/common.module';

.editProjectUser {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .sectionDivider {
        border-top: 1px solid $light-grey;
    }

    .section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
    }

    .sectionContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .sectionHeading {
        color: $black;
        font-size: 14px;
    }

    .title {
        font-size: 14px;
        color: $dark-teal;

    }

    .header {
        display: flex;
        overflow: auto;

    }

    .sectionRow {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

    }

    .fields {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .phoneInput {
            display: grid;
            grid-template-columns: 70px 235px;
            gap: 5px;
        }
    }

    .editText {
        font-size: 12px;
        color: $dark-teal;
        cursor: pointer;
    }

    .bottomNavigation {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;

        &Row {
            flex-direction: row;
        }
    }

    .mainText {
        font-size: 14px;
        font-weight: $normal;
    }

    .infoText {
        display: flex;
        flex-direction: row;
        font-size: 12px;
        color: $dark-grey;
        font-weight: $normal;

        svg {
            margin-top: 3px;
        }

        span>p {
            margin-left: 5px;
        }
    }

    .notifyUser {
        display: flex;
        align-items: center;

        span {
            font-size: 12px;
            white-space: nowrap;
            color: $dark-grey;
        }
    }

}

.resendEmailContainer {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 5px;

    .row {
        display: flex;
        padding: 15px;
        gap: 10px;

        .info {
            display: flex;
            align-items: center;
            gap: 10px;

            svg {
                color: $orange;
            }
        }

        button {
            margin-left: auto;
        }
    }
}


.editProjectUserHeader{
    display: flex;
    flex-direction: column;
    gap: 16px;

    .userDetails {
        display: flex;
        gap: 16px;

        .avatar {
            height: 44px;
            width: 44px;
            text-align: center;
            padding: 7px;
            font-size: 20px;
            background-color: $medium-light-grey;
            color: $dark-grey;
            border-radius: 50%;
            font-weight: 400;
        }

        .info {
            display: flex;
            flex-direction: column;
            font-weight: 400;
            gap: 4px;

            .name {
                font-size: 14px;
                color: $black;

                &Inactive {
                    color: $dark-grey;
                }
            }

            .contact {
                font-size: 12px;
                color: $dark-grey;

                &Inactive {
                    color: $grey;
                }
            }

            .icons {
                display: flex;
                gap: 24px;

                .adminIcon {
                    display: flex;
                    gap: 4px;
                    color: $dark-blue;
                    font-size: 12px;
                    align-items: center;
                }
            }
        }

        }


        .actions {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

        }
    }


    .unassignUser{

        display: flex;
        flex-direction: column;
        gap: 16px;

        .footerActions{
            display: flex;
            gap: 16px;
            justify-items: stretch;
            button{
                flex-grow: 1;
                flex-basis: 0;
            }
        }
    }