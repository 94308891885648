@import 'src/styles/common.module';

.title{
    font-size: 24px;
    color: $dark-teal;
    margin-bottom: 10px;
}


.content{

    table{
        tr {
            td:first-of-type {
                font-weight: bold;
                padding-right: 20px;
            }

            td{
                padding-bottom: 20px;
                vertical-align: top;
            }
        }
    }

    h1,h2,h3,h4,h5,h6{
        color: $dark-teal;
        font-weight: $normal;
    }

    p{
        margin-bottom: 20px;
    }   

    code{
        background-color: $white;
        padding: 4px 8px;
        border-radius: 8px;
        color: $dark-teal;
        font-size: 12px;
        border: 1px solid $dark-teal;
    }
}