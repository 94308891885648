@import 'src/styles/common.module';

.titleContainer {
    display: flex;

    .title {
        margin-left: 25px;
        padding-top: 25px;
        font-size: 20px;
        color: $dark-teal;
    }

    .yAxisTile {
        margin-left: 80px;
        padding-top: 30px;
        font-size: 18px;
        color: $dark-grey;
    }

}