@import 'src/styles/common.module';

.radioGroup {
  display: flex;
  .label {
    font-size: var(--radio-label-font-size, 14px);
    font-weight: var(--radio-label-font-weight, #{$normal});
  }
}

.radioGroupColumn {
  flex-direction: column;
  align-items: flex-start;
}

.radioGroupRow {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 10px;
}

.radioGroupItem {
  display: inline-grid;
  grid-template-columns: auto max-content;
  column-gap: 10px;
}

.input {
  width: 0;
  display: none;
}

.radio {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
  justify-self: center;
  margin-top: var(--radio-margin-top, 6px);
  background-color: var(--radio-background-color, #{$white});
  border: 1px solid var(--radio-border-color, #{$grey});
  height: var(--radio-diameter, 16px);
  width: var(--radio-diameter, 16px);

  &Checked {
    background-color: var(--radio-checked-background-color, #{$white});

    &:after {
      content: '';
      position: absolute;
      margin-top: 2px;
      margin-left: 2px;
      height: var(--radio-checked-diameter, 10px);
      width: var(--radio-checked-diameter, 10px);
      border-radius: 50%;
      background: $dark-teal;
    }

    &Disabled {
      border: 1px solid $medium-light-grey;
      
      &:after {
        content: '';
        position: absolute;
        margin-top: 2px;
        margin-left: 2px;
        height: var(--radio-checked-diameter, 10px);
        width: var(--radio-checked-diameter, 10px);
        border-radius: 50%;
        background: $medium-light-grey;
      }
      
    }

  }
  
  &Disabled {
    border: 1px solid $medium-light-grey;
  }
}

.label {
  color: var(--radio-label-color, #{$black});
  font-size: var(--radio-label-font-size, 12px);
  font-weight: var(--radio-label-font-weight, #{$light});
  line-height: 30px;
  padding-right: 2px;
  word-wrap: break-word;
  user-select: none;
  cursor: pointer;

  &Disabled {
    color: $grey !important;
  }

}
