@import 'src/styles/common.module';

.lozenge{
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .assay{
        font-weight: $bold;
    }
}
