@import 'src/styles/common.module';

.mapContainer {
    height: 660px;

    .legend {
        max-width: 250px;
        padding: 16px;
        margin-bottom: 24px;
        margin-left: 5px;
        border-radius: 8px;
        font-size: 14px;
        background: white;
        line-height: 16px;
        color: #555;
        cursor: default;
        box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.3);

        .title {
            font-size: 16px;
            font-weight: $semiBold;
            margin-bottom: 16px;
            color: #777;
        }

        label {
            padding: 0;
            margin: 0 0 8px 0;

            >span {
                padding: 0 !important;
                margin-right: 8px;
            }
        }

        .keyLabel {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            word-wrap: break-word;

            i {
                width: 15px;
                height: 3px;
                margin-right: 8px;
                flex-shrink: 0;
                display: inline-block;
            }
        }
    }
}