@import 'src/styles/common.module';

.projectContact{
    border: 1px solid $dark-teal;
    border-radius: 4px;
    padding: 9px;
    display: grid;
    row-gap: 10px;
    grid-template-columns: 30px 1fr;
    grid-template-areas: 'icon contact'
    'empty role';

    .userIcon{
        grid-area: icon;
        display: flex;
        color: $dark-teal;
    }

    .contactDetails{
        grid-area: contact;
        display: flex;
        flex-direction: column;

        &Name{
            font-weight: $bold;
        }
    }

    .role{
        grid-area: role;
        color: $dark-teal;

        .roleTitle{
            display: flex;
            align-items: center;
        }
    }

}