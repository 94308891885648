@import 'src/styles/common.module';

  .filters{

    display: flex;
    column-gap: 23px;

    .filterGroup{
      display: flex;
      column-gap: 32px;
      padding-left: 32px;
      align-items: flex-end;
    }

    .selectGroup{
      display: flex;
      column-gap: 20px;
    }
    .selectInput{
      display: flex;
      flex-direction:column;
    }
  }

  .resetButtonBucket{
    align-self: flex-end;
  }
  .resetButton{
    padding: 12px;
    font-size: 14px;
    padding: 4px 12px;
    width: 160px;
    display: flex;
    gap: 6px;
    svg{
      border: 1px solid $dark-teal;
      border-radius: 50%;
    }
  }

  .filterLabel{
    color: $dark-teal !important;
    font-size: 14px !important;
  }