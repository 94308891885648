@import 'src/styles/common.module';


.tableWrapper{
    height: 100%;
    overflow: auto;
}
.dataTable {
    --table-height: 100%;
    flex: 1 1 auto;
    color: #262d38;

    th{
        div{
            max-width: 150px;
        }
    }

}

.otherActions {
    display: flex;
    align-items:  flex-start;
    justify-content: flex-end;
    gap: 30px;
  }

  .downloadButton{
    font-size: 14px;
    font-weight: $bold;
  }

.reportDate{
    display: flex;
    flex-direction: column;
    &Label{
        font-size: 14px;
    }

    &Value{
        font-weight: $bold;
    }
}