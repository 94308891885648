@import 'src/styles/common.module.scss';

.passwordInput{
    position: relative;
    height: 40px;
    .input{
        padding-right: 40px;
    }

    .visibilityIcon{
        position: absolute;
        cursor: pointer;
        top: 50%;
        margin-top: -12px;
        right: 16px;
        height: 24px;
        width: 24px;
        color: $grey;
    }

    &.disabled{
        .visibilityIcon{
            cursor: default;
        }
    }
}
