.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(black, 0.2);
    z-index: 1300;
  }
  
  .body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: var(--modal-body-width, min-content);
    height: var(--modal-body-height, auto);
    min-width: 30vw;
    max-width: var(--modal-body-max-width, 80vw);
    max-height: 80vh;
    background: white;
    padding: 30px 45px;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    overflow: auto;
    border-radius: 8px;
  }
  
  @media (max-width: 980px) {
    .body {
      width: 95vw;
      max-width: 80vw;
    }
  }
  
  .close {
    position: absolute;
    right: 20px;
    top: 17px;
    z-index: 1;
  }

  .content{
    position: relative;
    height: 100%;
    width: 100%;
  }