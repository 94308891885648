@import 'src/styles/common.module';

.uploadWrapper {
    width: 720px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .top {
        display: flex;
        justify-content: space-between;

        .topLeft {
            display: grid;
            gap: 8px;
            grid-template-areas: 'icon title'
                'icon fileType';

            .uploadIcon {
                grid-area: icon;
                align-self: center;
                color: $dark-grey;
            }

            .uploadTitle {
                grid-area: title;
                font-size: 20px;
            }

            .uploadFileType {
                grid-area: fileType;
                color: $dark-grey;
            }
        }

        .topRight {
            display: flex;
            align-items: flex-end;

        }
    }

    .bottom {

        .uploadInput {
            --drop-zone-width: 100%;
        }
    }

    .uploadedFileContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f0f0f0;
        padding: 20px;

        .uploadedFileIconContainer {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            color: $dark-grey;

            .uploadedFileNameText {
                color: $black;
            }

        }
    }

    .modalContainer {
        display: flex;
        flex-direction: column;

        .title {
            font-size: 17px;
            margin-bottom: 10px;
        }

        .subTitle {
            font-size: 14px;
            color: $dark-grey;
            margin-bottom: 20px;
        }

        .buttonContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 20px;

            button {
                width: calc(25% - 5px);

            }
        }
    }
}