@import 'src/styles/common.module';

.filtersContainer{
    position: relative;
    display: flex;
    gap: 15px;
    align-items: flex-end;
    margin-bottom: 10px;
}

.filters{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 400px;
    padding: 30px;
}

.closeButton{
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 20px;
    height: 40px;
    width: 40px;
    cursor: pointer;
}
.filterDateRage{
    .filterDateRageBucket{
        margin-top: 1rem;
        justify-content: space-between;
         display: flex;
         align-items: center;
        .selectDate{
            width: 35%;
           }
        }
    }

.filterLabel{
    margin-bottom: 0.5rem;
    font-size:14px !important;
    font-weight: 600; 
    color: #076769 !important
}

.selectInput{
        div{font-weight: 400;color: #121828}
}