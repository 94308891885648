@import 'src/styles/common.module';

.chartContainer {
  border-top: 1px solid #ccc;
  padding-top: 47px;
}

.habitatInsights{
  border-radius: 4px;
  background: #FFF;
  box-shadow: '0px 1px 2px 0px rgba(100, 116, 139, 0.10), 0px 1px 1px 0px rgba(100, 116, 139, 0.06)';
  padding: 32px 24px;
}

.chartTitle {
  & h3 {
    color: $dark-teal;
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
  }
}