@import 'src/styles/common.module';

.wrapper{
    background-color: $white;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    border-radius: 10px;

    display: grid;
    grid-template-columns: 1fr 500px;
    height: 100%;

    .mainContent{
        padding: 30px;
        transition: width .2s linear;
        border-right: 2px solid $medium-light-grey;
        display: block;
        overflow: visible;
        
        .textAboveChart{
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
            border-bottom: 2px solid $medium-light-grey;
            .descriptionAboveChart{
                margin-right: 2rem;
            }
        }

        .textBelowChart{
            padding-bottom: 10px;
        }

    }
}


.filters{
    display: flex;
    gap: 10px;
    align-items: center;;
    .filterLabel{
        color: $dark-teal;
    }
}


.charts{

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;

    .year{
        text-align: center;
        font-size: 20px;
    }
}

.legends{
    display: flex;
    flex-direction: row;
    gap: 30px;
    .legend{
        display: inline-flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        .legendIcon{
            height: 10px;
            width: 10px;
            border-radius: 100%;
            display: flex;
            vertical-align: middle;
            justify-items: center;
        }

        &Opaqued{
            opacity: .3;
        }
    }
}

.textHighlight{
    color: $teal;
}

.sampleGroupStats{

    display: flex;
    flex-direction: column;
    gap: 10px;

}