@import 'src/styles/common.module';

.testTypeChip{

    display: flex;
    align-items: center;
    gap: 8px;
    color: $black;
    font-size: 12px;
    img{
        height: 38px;
        width: 38px;
    }

}