@import 'src/styles/common.module';

.fishMetric{
    width: 100%;
    display: flex;
    padding: 32px 24px;
    background-color: $white;
    border-radius: 10px;
    align-items: stretch;
    justify-items: stretch;

    .backDrop{
        position: relative;
        flex: 1;
        border-radius: 10px;
        overflow: hidden;

        @media screen and (max-width: 1056px) {
            display: none;

        }
    }


    .chart{
        flex: 1;
    }
}
