@import 'src/styles/common.module';

.viewProjectUser{
    display: flex;
    flex-direction: column;
    gap: 36px;

    .title{
        font-size: 20px;
        color: $dark-teal;
    }

    .popupSections {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .sectionHeading {
            font-size: 14px;
            margin: 8px 0;
        }

        .sectionExpandableHeading {
            display: flex;
            font-size: 14px;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            svg {
                color: $dark-grey;
            }
        }

        .orgList {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .orgDetail {
                display: flex;
                flex-direction: column;
                gap: 8px;
                border-radius: 8px;
                border: 1px solid $dark-teal;
                padding: 8px;

                .orgTitle {
                    font-size: 14px;
                    color: $dark-teal;
                }
            }
        }
    }

    .userDetails {
        display: flex;
        flex-direction: column;

        .statusIndicator {
            display: flex;
            gap: 16px;
            
            .leftSpacing {
                width: 44px;
                padding: 7px;
            }

            .indicatorIcon {
                display: flex;
                align-items: center;
                font-size: 14px;
            }
        }

        .resendEmailContainer{
            display: flex;
            flex-direction: column;
            background-color: #FFFFFF;
            border-radius: 8px;
            padding: 12px;
            gap: 12px;
            margin-bottom: 8px;
            .row {
                display: flex;
                .info{
                    display: flex;
                    color:$orange;
                    gap: 8px;
                    align-items: center;
                    p {
                        font-size: 12px;
                        color: $black;
                        margin: 0px;
                    }
                }
                button{
                    margin-left: auto;
                    padding: 8px;
                    font-size: 12px;
                }
            }
        }
    }

    .sectionSeparator {
        height: 1px;
        background-color: $medium-light-grey;
    }

    .userContact {
        font-size: 14px !important;
    }

}

.noOtherOrgsMessage {
    background: $medium-blue;
    margin-bottom: 0px !important;
}

.orgSectionTitle{
    margin-bottom: 20px;
}