@import 'src/styles/common.module';

.legend{
    span{
        margin-top: -5px;
    }
    
}


.timeseriesCard{
    padding-left: 16px;
}


.tabsWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 10px 0;
}
.tabs{
    gap: 0 !important;
}

.tabDefault{
    color: $dark-teal;
    background-color: $white;
    border: 1px solid $dark-teal;
    padding: 6px 10px 6px 10px !important;

    &:first-of-type{
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    &:last-of-type{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}

.tabSelected{
    color: $white !important;
    background-color: $dark-teal;
}