@import 'src/styles/common.module';

.wrapper {
  display: inline-block;
}

.grid {
  display: inline-grid;
  grid-template-columns: auto 1fr;
  font-size: 0;
  align-items: center;
}

.checkboxDefault {
  display: inline-block;
  cursor: pointer;
  justify-self: center;
  accent-color: var(--checkbox-background-color, #{$dark-teal});
  border: 1px solid var(--checkbox-border-color, #{$grey});
  height: var(--checkbox-side-size, 14px);
  width: var(--checkbox-side-size, 14px);
  transition: background-color 0.1s ease-in-out;
}

.grid .checkboxDefault {
  margin-top: var(--checkbox-margin-top, -1px);
}

.checkboxChecked {
    accent-color: var(--checkbox-checked-background-color, #{$dark-teal});
}

.checkboxDisabled {
  border-color: var(--checkbox-disabled-border-color, #{$light-grey});
  accent-color: var(--checkbox-disabled-background-color, #{$light-grey});
  cursor: default;
  pointer-events: none;
}

.label {
  color: var(--checkbox-label-color, #{$black});
  font-size: var(--checkbox-label-font-size, 13px);
  font-weight: var(--checkbox-label-font-weight, #{$normal});
  line-height: var(--checkbox-label-line-height, 30px);
  padding-left: 12px;
  cursor: pointer;
  user-select: none;
  word-wrap: break-word;
}

.checkboxDisabled + .label {
  cursor: default;
  color: $dark-grey;
}