@import 'src/styles/common.module';

.userDetailsForm {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .sectionSeparator {
        height: 1px;
        background-color: $medium-light-grey;
    }

    .firstUserInfo {
        background: $medium-blue;
        margin-bottom: 0px;
    }
}

.createUserFormWrapper {
    position: relative;

    .close {
        position: absolute;
        right: 0px;
        top: -10px;
        z-index: 1;
    }
}