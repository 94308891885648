@import 'src/styles/common.module';

.userTypeForm{

    display: flex;
    flex-direction: column;
    gap: 12px;

    .stepIndicator{
        color: $dark-grey;
        font-size: 14px;
    }

    .sectionHeading{
        color: $black;
        font-size: 14px;
        margin-bottom: 36px;
    }

    .fields{
        display: flex;
        flex-direction: column;
        gap: 24px;

        .label {
            color: $black;
            font-size: 17px;
        }

        .userType {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .userTypeLabel {
                color: $dark-grey;
            }
        }
    }
}