@import 'src/styles/common.module';
.card {
  display: flex;
  flex-direction: column;
    border-radius: 8px;
    padding: 20px 20px 30px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: $white;
    flex-wrap: wrap;
   box-sizing: border-box;
   flex: 1 1 0%;
}

  .header, .content, .footer {
    display: flex;
    justify-content: center; 
    align-items: center;
  }
  
  .header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    img {
      width: 28px;
      height: 28px;
  
    }

  }

  .title {
    margin: 0;
    font-size: 17px;
    color: $dark-teal;
    font-weight: $normal;
  }

  .questionButton{
    margin-top: -4px;
  }
 

  .content {
    display: flex;
    align-items: baseline; 
    font-size: 16px;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 20px;

    .number {
        font-size: 52px;
        margin-right: 10px; 
        line-height: 42px;

      @media only screen and (max-width: 919px) {
        font-size: 44px;
      }
    }
    

    .noDataContent{
      font-size: 18px;
    }
      
  }
  
 

  .footer{
    margin-top:20px;
    justify-content: flex-start;
    color: $grey;
  }
  
  
  