@import 'src/styles/common.module';

.default{
    background-color: #E6E6E6; 
    color: #212121
}
.chip {
    padding: 4px 8px;
    gap: 8px;
    height: 30px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: $normal;
}

.invasive{
    background-color: #CAE1F6; 
    color: #386EB9
}
.redList{
    background-color: #F9DFE0; 
    color: #B42E2E
}

.chegd{
    background-color: $light-orange; 
    color: $dark-orange
}