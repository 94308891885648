@import 'src/styles/common.module';

.breadcrumbs {
    font-size: 12px;
    color: $dark-grey;
    color:#999999;
    .pathLink{
        color:#999999;
        text-decoration: none;
    }
     .pathLink:hover{
        color:#076769;
        text-decoration: underline;
        cursor: pointer;
    }
   
}