@import 'src/styles/common.module';

.dashboard{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    @media screen and (max-width: 1024px) {
        padding:0 16px;
        }
    }
.topContent{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.filters{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content:flex-end;
}
.titleBar{
    width: calc(50% - 10px);
    @media only screen and (max-width: 1024px) {
        width: 100%;
    }

    .title{
        font-size: 36px;
        margin-bottom: 8px;
        font-weight: 400;
    }
    
    .subTitle {
        font-size: 20px;
        font-weight: 400;
        color: #666666;
    }
}

.dateFilters {
    width: calc(50% - 10px);
    @media only screen and (max-width: 1024px) {
        margin-top:2rem;
        width: 100%;
    }

    line-height: 40px;
    display: flex;
    align-items: flex-end;
    gap: 10px;

}

.lozenge{
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .assay{
        font-weight: $bold;
    }
}

.nowrap{
    display: inline-flex;
    white-space: nowrap;
    span{
        line-height: 30px;
        display: flex;
        align-self:center;
        padding:0 5px 0 0;
    }
    label {
        display: none
    }
    fieldset{
        top:0px;
        legend{display:none}
    }
}
