@import "src/styles/common.module";


.siteMenu{
    background-color: $white;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 82px;
    max-width: 82px;
    box-shadow: rgba(24, 39, 75, 0.08) 0px 0px 6px 3px;
    display: grid;
    grid-template-rows: 110px 64px 1fr max-content auto;
    grid-template-areas: 'logo'
        'toggle'
        'menuItems'
        'footer';
    z-index: 1002;

    transition: width linear 0.1s, max-width linear 0.1s;

    .logo{
        grid-area: logo;
        align-self: center;
        justify-self: center;
        width: 100%;
        margin-left: 29px;
        img{
            margin-top: 10px;
            height: 60px;
        }
    }

    .toggle{
        grid-area: toggle;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        background-color: $medium-light-grey;
        border-radius: 50%;
        height: 44px;
        width: 44px;
        cursor: pointer;

        &:hover{
            background-color: $light-grey;
        }
    }

    .menuItems{
        display: flex;
        flex-direction: column;
        overflow: auto;
        align-items: center;
        gap: 1px;

        .menuItem{
            width: 82px;    
            display: flex;
            flex-direction: column;
            padding: 10px 1px;
            align-items: center;
            text-align: center;
            color: $dark-grey;
            cursor: pointer;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            align-self: center;
            border-left: 2px solid $white;
            
            .icon{
                width: 24px;
                height: 24px;

            }
            .label{
                align-self: center;
            }
            
            &:hover{
                background-color: $medium-light-grey;
            }

            .chevron{
                --chevron-button-fill: #{$dark-grey};
            }

            &Active{
                border-left: 2px solid $dark-teal;
                background-color: rgba(20, 101, 104, 0.12);
                color: $dark-teal;
                .chevron{
                    --chevron-button-fill: #{$dark-teal};
                }

                &:hover{
                    background-color: rgba(20, 101, 104, 0.12);;
                }
            }

            &Open{
                flex-direction: row;
            }
        }
    }

    .footer{
        border-top: 1px solid $medium-light-grey;
    }

    .userMenu{
        cursor: pointer;
        &:hover{
            background-color: $medium-light-grey;
        }

        .userIcon{
            background-color: $light-grey;
            color: $dark-grey;
            border-radius: 50%;
            height: 44px;
            width: 44px;
            text-align: center;
            font-size: 20px;
            padding: 7px 0;
            margin: 10px auto 0 ;
            cursor: pointer;
        }

        .contactIcon{
            height: 44px;
            width: 44px;
            margin: 4px auto 0 ;
            cursor: pointer;
        }

        .contactLabel{
            align-self: center;
            font-size: 10px;
        }
    }


    &Open{
        width: 256px;
        max-width: 256px;
        
        .menuItems{
            margin: 4px 8px;
            .menuItem{
                border-left: none;
                border-radius: 4px;
                width: 100%;
                text-align: left;
                padding: 8px;
                min-height: 66px;
                flex-direction: row;
                align-items: center;
                gap: 20px;

                .label{
                    margin-right: auto;
                }
                &Child{
                    min-height: 40px;
                    padding-left: 51px;
                }
            }
        }

        .userMenu{
            display: flex;
            gap: 20px;
            align-items: center;
            .userIcon{
                background-color: $light-grey;
                color: $dark-grey;
                border-radius: 50%;
                height: 44px;
                width: 44px;
                text-align: center;
                font-size: 20px;
                padding: 7px 0;
                margin: 0 0 0 10px;
                cursor: pointer;
            }

            .contactIcon{
                height: 44px;
                width: 42px;
                margin: 0 0 0 5px;
                cursor: pointer;
            }

            .contactLabel{
                font-size: 14px;
            }
        }
    }

}

.siteMenuOverlay{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.7);
}

.logoutConfirmationTitle{
    color: $dark-teal;
}