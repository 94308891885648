@import 'src/styles/common.module';

.statusChip{
    padding: 4px 8px;
    background-color: var(--chip-background-color, $dark-teal);
    color: var(--chip-text-color, $white);
    border-radius: 20px;
    justify-content: flex-start;
    align-items: center;
    gap: 8;
    display: inline-flex;
    max-width: min-content;

    &warning{
        color: $orange;
        background-color: $light-orange;
    }
    &success{
        color: $green;
        background-color: $light-green;
    }
    &error{
        color: $red;
        background-color: $light-red;
    }
    &disabled{
        color: $grey;
        background-color: $light-grey;
    }
    &unknown{
        color: $light-grey;
        background-color: transparent;
    }
}