@import 'src/styles/common.module';


.bannerContainer{
    display: flex;
    padding: 12px;
    border-radius: 8px;
    border: 4px solid $red;
    background: #FFF;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    .messageBox{
        display: flex;

        .messageBoxContent{
            display: flex;
            flex-direction: column;

            .message{
                color: $red;
                font-size: 17px;
                font-weight: $bold;
            }
            .description {
                font-size: 17px;
                text-decoration-line: underline;
                cursor: pointer;
            }
        }
        svg {
            margin-right: 8px;
            color: $red;
        }
       
    }

    .interestBox {
        display: flex;
        justify-content: space-between;
        .createUserButton{
            margin-left: 12px;
            font-size: 17px;
            width:263px;
            span{
                font-size: 24px;
                margin-left: 8px;
            }
        }
    }
}


.bannerContainer{
    display: grid;
    grid-template-areas: 
        "icon interestBox";
}