@import 'src/styles/common.module';

.chartContainer{
   border-top: 1px solid #ccc;
}
.statisticalComparison{
    display: grid;
    gap: 20px;
    grid-template-columns: 320px auto;
    margin-top: 2rem;
}

.plot{
    background-color: $white;
    box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
    padding: 32px 24px;
    border-radius: 10px;

    width: 100%;
    display: flex;
    flex-direction: column;

    &Header{
        padding: 5px 24px 10px;
        margin: 0 -24px 0;

        display: grid;
        grid-template-areas: 'title controls'
                            'help controls';
        grid-template-columns: 500px auto;
        column-gap: 90px;
        row-gap: 20px;

        &Title{
            grid-area: title;
            font-size: 18px;
        }
        &Help{
            display: flex;
            column-gap: 40px;
            grid-area: help;

            .help{
                color: $dark-teal;
                align-items: center;
                display: flex;
                gap: 5px;
            }
            
        }
        &Controls{
            grid-area: controls;
            display: flex;
            flex-direction: column;
            justify-items: flex-end;
        }

    }

    &Container{
        display: grid;
        grid-template-areas: 'metric plot';
        grid-template-columns: 105px min-content;
        margin-bottom: 20px;

        .plotMetric{
            grid-area: metric;
            display: flex;
            align-items: center;
        }

        &Plot{
            grid-area: plot;
        }

    }

    .plotFooter{
        display: grid;
        grid-template-areas: 'help'
        'description';
        justify-content: space-between;
        row-gap: 40px;
        color: #076769;
        
        &Help{
            color: $dark-teal;
            display: flex;
            align-items: center;
            gap: 10px;
        }

    }


}


.anotomyImage{
    height: 300px;
}

.comparing{
    color: #A71B1B;
}

.comparingWith{
    color: #E3A52D;
}
.borderTop{
    border-top:1px solid #ccc;
}