@import 'src/styles/common.module.scss';


.button{
  // Remove the focus ring around an element when a mouse input is detected.
  &[data-whatinput='mouse'] {
    outline: 0;
  }
  appearance: none;
  border: 0;
  background: transparent;
  line-height: 1;
  cursor: pointer;

}

.chevronButton{
  --chevron-fill: var( --chevron-button-fill, #{$dark-teal});
}

.chevronButtonDisabled {
  --chevron-fill: #{$grey};
  cursor: pointer;

  &Disabled {
    cursor: default;
  }
}

