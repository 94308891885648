@import 'src/styles/common.module';

.samplingEventsLabelWrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 2px 0;
    font-size: 14px;
    font-weight: $normal;
    color: $black;
    .labelNumber {
      font-size: 14px;
      font-weight: $normal;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $medium-light-grey;
      text-align: center;
    }
    .labelText {
      display: flex;
      flex-direction: column;
      .row2 {
        color: $dark-grey;
      }
    }
}

