@import 'src/styles/common.module';

.emptyState {
    .iconEmptyState {
        text-align: center;
        margin: 4rem auto;
    }
    .emptyStateMessage {
        max-width: 525px;
        margin: 5rem auto 0;
        text-align: center;
        h3 {
            font-size: 29px;
            font-weight: 400;
            color: $black;
        }
        p {
            font-size: 17px;
            font-weight: 400;
            color: $dark-grey;
        }
    }
    .buttonBar {
        text-align: center;
        margin: 32px auto;
        display: flex;
        justify-content: center;

        .createUserButton{
            span{
                font-size: 24px;
                margin-left: 8px;
            }
        }
    }
}