@import 'src/styles/common.module';

.standardAppLayout{
    display: flex;
    flex-flow: column;
    padding-right: 32px;
    padding-left: 114px;
    min-height: 100vh;
    max-height: 100vh;
    height: 100%;
    overflow: auto;
}


.topContent{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    flex: 0 1 auto;
    column-gap: 10px;
    padding-bottom: 20px;
    padding-top: 32px;
}


.breadcrumbs{
    flex: 0 1 auto;
}


.mainContent{
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    > div{
        flex: 1;
    }
}

.filters{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-self: stretch;
    justify-content: flex-end;

}
.otherActions{
     align-self: flex-end;
}

.titleBar{
    width: calc(60% - 10px);
    @media only screen and (max-width: 1024px) {
        width: 100%;
    }

    .title{
        font-size: 36px;
        margin: 0 0 8px 0;
        font-weight: $normal;
    }
    
    .subTitle {
        font-size: 20px;
        font-weight: $normal;
        color: #666666;
        margin: 8px 0 20px 0;
    }
}

.lozenge{
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .assay{
        font-weight: $bold;
    }
}
