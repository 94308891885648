@import 'src/styles/common.module';

.assignProjects {
    display: flex;
    flex-direction: column;
    gap: 12px;


}

.projectName {
    font-size: 17px;
}

.assignProjectCard {
    border: 2px solid $dark-teal;
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 5px;

    &Disabled {
        border: 1px solid $grey;
        color: $dark-grey;
    }

    .title {
        font-size: 14px;
        color: $dark-teal;


        &Disabled {
            color: $grey;
        }

        &ViewOnly {
            color: $dark-grey;
        }


    }

    .roleTitle {
        margin-top: 8px;
        font-size: 14px;

        &Disabled {
            color: $grey;
        }

        color: $dark-grey;
    }

    .roleContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-bottom: 8px;
    }

    .roleItem {
        width: calc(50% - 6px);
        box-sizing: border-box;
        border-radius: 8px;
        background: #fff;
        padding: 6px 8px;
        font-size: 14px;
        border: 1px solid transparent;

        &error {
            border: 1px solid $red;
        }

        &warning {
            border: 1px solid $orange;
        }

        &info {
            border: 1px solid $blue;
        }

        .roleCheckbox {
            height: 16px;
            width: 16px;
            border: 1px solid $dark-grey;
            margin: 0px;
        }

        .roleItemLabel {
            color: $dark-grey;
            font-size: 14px;

            &Disabled {
                font-size: 14px;
                color: $grey;
            }
        }
    }

}

.addProjectButtonContainer {
    display: flex;
    justify-content: center;

    .addProjectButton {
        font-size: 14px;

        span {
            font-size: 24px;
            margin-right: 8px;
        }

        &:disabled {
            color: $medium-light-grey;
        }
    }
}

.unassignContainer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $medium-light-grey;
    padding-top: 2px;

    .unassignButton {
        font-size: 12px;
        padding: 8px;
    }
}

.closeButton {
    position: absolute;
    right: -25px;
    top: -25px;
    z-index: 9999;
    fill: $white;
    background: $dark-teal;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    height: auto;
    color: $white;
}

.iconContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    color: $dark-teal;

    &Disabled {
        color: $grey;
    }

    .icon {
        height: 24px;
        width: 24px;
        color: $dark-grey;
    }

    .text {
        font-size: 12px;
        color: $dark-grey;

        &error {
            color: $red;
        }

        &warning {
            color: $orange;
        }

        &Disabled {
            color: $grey;
        }
    }
}

.warningSection {
    margin-bottom: 5px;

    .expandedSection {
        font-size: 12px;
        margin-bottom: 10px;

        &error {
            background-color: $light-red;
        }

        &info {
            background-color: $light-blue;
        }

        &warning {
            background-color: $light-yellow;
        }


        .content {
            display: flex;
            flex-direction: column;
            padding: 20px;
            gap: 5px;

        }

        .info {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }
}

.chevron {
    margin-top: 10px;
}