@import 'src/styles/common.module';

.createNewUserForm{

    display: flex;
    flex-direction: column;
    gap: 20px;

    .title{
        font-size: 14px;
        color: $dark-teal;
    }

    .stepIndicator{
        color: $dark-grey;
        font-size: 14px;
    }

    .sectionHeading{
        color: $black;
        font-size: 14px;
    }

    .fields{
        display: flex;
        flex-direction: column;
        gap: 20px;

        .phoneInput{
            display: grid;
            grid-template-columns: 70px 235px;
            gap: 5px;
        }
    }
}