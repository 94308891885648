@import 'src/styles/common.module.scss';

.tableControls {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}
