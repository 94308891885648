@import 'src/styles/common.module';

.differenceIndicator{
    display: flex;
    justify-content: space-between; 
    flex-direction: column;
    gap: 21px;
    text-align: center;
 
    svg {
        margin: 0 auto;
    }
    .title{
        font-size: 17px;
        font-weight: $bold;
    }
}