
@import 'src/styles/common.module';

.iucnMetrics{

    padding: 32px;
    .filter{
        display: flex;
        gap: 20px;
        font-size: 14px;
        margin-bottom: 20px;
        .title{
            color: #666666;
        }
        .filterLabel{
            font-size: 14px;
            color: $dark-teal;
           
        }  

        .filterSelect{
            margin-top: -10px;
        }
    
    }    
}