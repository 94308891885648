@import 'src/styles/common.module.scss';

.input {
  display: inline-block;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 0.5px solid $dark-teal;
  font-size: 14px;
  line-height: 16.80px;
  color: $black;
  background-color: $white;
  padding: 0 16px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; 
  }

  &.hasError{
    border-color: $red;
  }

  &:focus,
  &:active {
    outline: none;
  }

  &:disabled{
    background-color: $light-grey;
    &::placeholder {
      color: $grey;
    }
    &:hover{
      background-color: $light-grey;
    }
  }

  &:hover{
    background-color: $medium-light-grey;
  }

  &:focus{
    background-color: $white;
    box-shadow: 0px 4px 4px -2px rgba(24, 39, 75, 0.08);

  }

}


.limit{
  text-align: right;
  color: $dark-teal;

}