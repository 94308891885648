@import 'src/styles/common.module';

.singleTimepointMetricsOverview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 32px;
    gap: 20px;
    justify-content: space-between;

    // X-Small breakpoint
    @media (max-width: $xSmall) {
        flex-direction: column;
    }

    // 3 cards
    &:has(> :nth-child(3):last-child) {
        @media (max-width: $medium) {
            > :nth-child(1) {
                flex: 1 0 100%;
            }
            > :nth-child(2),
            > :nth-child(3) {
                flex: 1 0 calc(50% - 20px);
            }
        }

        @media (max-width: $small) {
            > div {
                flex: 1 1 100%;
            }
        }
    }

    // 4 cards
    &:has(> :nth-child(4):last-child) {
        @media (min-width: $large) {
            > div {
                flex: 1 0 calc(25% - 20px);
            }
        }

        @media (max-width: $medium) {
            > div {
                flex: 1 0 calc(50% - 20px);
            }
        }

    }

    // 5 cards
    &:has(> :nth-child(5):last-child) {
        @media (min-width: $large) {
            > div {
                flex: 1 0 calc(20% - 20px);
            }
        }

        @media (max-width: $large) {
            > :nth-child(1),
            > :nth-child(2) {
                flex: 1 0 calc(50% - 20px);
            }

            > :nth-child(3),
            > :nth-child(4),
            > :nth-child(5) {
                flex: 1 0 calc(33.33% - 20px);
            }
        }

        @media (max-width: $small) {
            > :nth-child(1),
            > :nth-child(2),
            > :nth-child(4),
            > :nth-child(5) {
                flex: 1 0 calc(50% - 20px);
            }
            > :nth-child(3) {
                flex: 1 0 100%;
            }
        }
    }

    // 6 cards
    &:has(> :nth-child(6):last-child) {
        @media (min-width: $medium) {
            > div {
                flex: 1 0 calc(33.33% - 20px);
            }
        }

        @media (max-width: $medium) {
            > div {
                flex: 1 0 calc(50% - 20px);
            }
        }
    }

    // 7 cards
    &:has(> :nth-child(7):last-child) {
        @media (min-width: $large) {
            > :nth-child(1),
            > :nth-child(2),
            > :nth-child(3) {
                flex: 1 0 calc(33.33% - 20px);
            }

            > :nth-child(4),
            > :nth-child(5),
            > :nth-child(6),
            > :nth-child(7) {
                flex: 1 0 calc(25% - 20px);
            }
        }

        @media (max-width: $large) {
            > :nth-child(1),
            > :nth-child(2),
            > :nth-child(6),
            > :nth-child(7) {
                flex: 1 0 calc(50% - 20px);
            }

            > :nth-child(3),
            > :nth-child(4),
            :nth-child(5) {
                flex: 1 0 calc(33.33% - 20px);
            }
        }

        @media (max-width: $small) {
            > div {
                flex: 1 0 calc(50% - 20px);
            }
            > :nth-child(3) {
                flex: 1 0 100%;
            }
        }
    }

    // 8 cards
    &:has(> :nth-child(8):last-child) {
        @media (min-width: $small) {
            > div {
                flex: 1 0 calc(25% - 20px);
            }
        }

        @media (max-width: $small) {
            > div {
                flex: 1 0 calc(50% - 20px);
            }
        }
    }
}
