@import 'src/styles/common.module';

.selectExistingUser{

    display: flex;
    flex-direction: column;
    gap: 20px;

    .label{
        font-size: 14px;
        color: $dark-teal;
        &Error{
            color: $red;
        }
    }

    .fields{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

.userInfo{
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    .close {
        position: absolute;
        right: 0px;
        top: -10px;
        z-index: 1;
      }
}

.sectionSeparator {
    height: 1px;
    background-color: $medium-light-grey;
}

.currentOrganisations{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.userOrganisation{
    border-radius: 6px;
    border: 1px solid $dark-teal;
    padding: 6px;

    .name{
        color: $dark-teal;
    }
}