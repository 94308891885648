@import 'src/styles/common.module';

.newProjectUser{
    display: flex;
    flex-direction: column;
    gap: 36px;

    .title{
        font-size: 20px;
        color: $dark-teal;
    }
}

.saveHint{
    display: flex;
    gap: 10px;

    .saveHintIcon{
        color: $dark-grey;
    }

    .saveHintText{
        font-size: 12px;
        color: $dark-grey;
    }
}