@import 'src/styles/common.module';

.userManagementList {

    background-color: $white;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;

    thead tr,
    tbody tr {
        background-color: $white;
    }

    tbody tr {
        border-bottom: 1px solid $light-grey;
        cursor: pointer;
    }



    .basicDetails {
        display: flex;
        gap: 20px;
        padding: 10px 0;

        .avatar {
            height: 50px;
            width: 50px;
            text-align: center;
            padding: 10px;
            font-size: 20px;
            background-color: $medium-light-grey;
            color: $dark-grey;
            border-radius: 50%;
        }

        .values {
            display: flex;
            flex-direction: column;

            .email {
                color: $dark-grey;
            }
        }
    }

    .status {
        display: flex;
        gap: 5px;

        .statusIndicatorIcon {
            height: 12px;
            width: 12px;
            margin: 4px;
            border-radius: 50%;

            &Active {
                background-color: $green;
            }

            &Invited {
                background-color: $orange;
            }

            &Inactive {
                background-color: $red;
            }
        }

        .statusIndicatorText {
            color: $black;

            &Inactive {
                color: $grey;
            }
        }
    }

    .projectTags {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;

        .projectTag {
            padding: 2px 8px;
            border-radius: 8px;
            font-size: 12px;
            font-weight: 400;
            height: 24px;
            text-align: center;
            border: 1px solid $dark-teal;
            color: $dark-teal;
            overflow: hidden;
            text-overflow: ellipsis;

            &Inactive {
                border: 1px solid $grey;
                color: $grey;
            }
        }
    }

    .totalRoles {
        text-align: center;
        color: $black;

        &Inactive {
            color: $grey;
        }
    }

    .actions {
        display: flex;
        align-items: center;
        color: $dark-teal;

        & span {
            margin-left: 5px;
        }
    }

    .customerAdminLogo {
        width: 32px;
        height: 32px;
        color: $dark-blue;
    }


    .noRecordsMessage {
        font-size: 17px;
        color: $black;
        padding: 12px;
    }

    .actionButtons {
        display: flex;
        gap: 4px;
    }
}


.newUserModal {
    height: 100vh;
    width: 400px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: unset;
    max-height: 100vh;
    transform: unset;
    border-radius: 0;
    background-color: $extra-light-grey;
    padding: 24px;
}