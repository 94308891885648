@import 'src/styles/common.module';

.chip{
    padding: 8px;
    background-color: var(--chip-background-color, $dark-teal);
    color: var(--chip-text-color, $white);
    border-radius: 8px;
    justify-content: flex-start;
    align-items: center;
    gap: 8;
    display: inline-flex;

}

.chip + .chip{
    margin-left: 4px;
}