.loginContainer{
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.centerBoxContainer{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    background: rgba(133, 133, 133, 0.5);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: auto;


    .centerBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        border: 1px solid #ccc;
        padding: 45px 20px;
        width: 420px;
    }
}

.logoContainer{
    display: flex;
    align-items: center;
    img {
        height: 80px;
    }
}