@import 'src/styles/common.module';

.timeseriesCardTemplate{
    display: flex;
    flex-direction: column;

    .top{
        display: flex;
        flex-direction: column;
    }

    .title{
        color: $dark-teal;
        font-size: 24px;
        font-weight: $mediumBold;
        margin-bottom: 0.5rem;
    }


    .averageWrapper{
        display: flex;
        gap: 12px;
        .averageValue{
            line-height: 1.2;
            font-weight: $medium;
            font-size: 72px;
            margin-right: 1rem;
            align-self: flex-end;
        }

        .averageValueDescription{
            align-self: flex-end;
            margin-bottom: 12px;
            color: $grey-60;
            font-size: 16px;
        }

    }


    .mean{
        display: flex;
        justify-content: space-between;
        .meanLeft{
            display: flex;
            gap: 12px;
            align-items: center ;
        }

        .meanRight{
            display: flex;
            gap: 12px;
            align-items: center ;
        }
    }
    
}

.chip {
    font-weight: normal;
    font-size: 14px;
    align-items: center;
    margin: 0 0.5rem 0 0;
    padding: 0 0.1rem;
    height: 20px;
    &.red {
        --chip-background-color: #F4AFAF;
        --chip-text-color: #B42E2E;
    }

    &.amber {
        --chip-background-color: #F7DFC3;
        --chip-text-color: #BD983A;
    }

    &.green {
        --chip-background-color: #C7F4B7;
        --chip-text-color: #5A824D;
    }
}


.tabs{
    margin: 0 auto;
}

