@import 'src/styles/common.module.scss';

.header {
  
  .headerCell {
    font-weight: $light;
    user-select: none;
    min-width: 100px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    padding: 25px 0;
  }

  .headerCellContent {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 5px;

    .title {
      padding-left: 7px;

      &Active {
        font-weight: $normal;
      }

      &Sortable {
        cursor: pointer;
      }
    }

    .sortArrowContainer {
      cursor: pointer;
    }

    .sortArrowContainer + .title {
      padding-left: 0px;
    }
  }
}

.questionButton {
  margin-left: 15px;
  height: 14px;
  width: 14px;
}

.sort {
  display: inline-block;
  position: relative;
}

.sortArrow {
  margin-right: 0.8em;
  height: 8px;
  width: 8px;
  transform: rotate(180deg);
  fill: $grey;

  &Active {
    fill: $teal;
  }

  &Up {
    transform: unset;
  }
}

.totalVisibleRows {
  font-weight: $normal;
}
