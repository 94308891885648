@import 'src/styles/common.module';

.projectContact{
    border: 1px solid $dark-teal;
    border-radius: 4px;
    padding: 9px;
    display: grid;
    row-gap: 10px;
    grid-template-columns: 30px 1fr;
    grid-template-areas: 'icon contact'
    'empty role';

    .userIcon{
        grid-area: icon;
        display: flex;
        color: $dark-teal;
    }

    .contactDetails{
        grid-area: contact;
        display: flex;
        flex-direction: column;

        &Name{
            font-weight: $bold;
        }
    }

    .role{
        grid-area: role;
        color: $dark-teal;

        .roleTitle{
            display: flex;
            align-items: center;
        }
    }

}

// TODO: Remove if following is not needed
// .addNewContactButton{
//     display: flex;
//     justify-content: center;
//     padding: 3px 0;
//     width: 100%;
//     border: 1px solid $dark-grey;
//     background-color: $extra-light-grey;
//     cursor: pointer;

//     &:hover{
//         background-color: $light-grey;
//     }

// }


// .customerContactList{
//     display: flex;
//     flex-direction: column;
//     gap: 10px;
//     margin-bottom: 20px;
// }


// .addNewContactForm{
//     border: 1px solid $dark-grey;
//     background: $medium-light-grey;
//     margin-top: 20px;
//     padding: 9px 20px;

//     &Title{
//         font-size: 18px;
//         margin-bottom: 10px;
//     }

//     .bottomSection{
//         display: grid;
//         column-gap: 10px;
//         grid-template-columns: 1fr 130px;

//         &Left{
//             display: flex;
//             flex-direction: column;
//             gap: 10px;
//         }

//         &Right{
//             display: flex;
//             width: 100%;
//             .addButton{
//                 align-self: end;
//                 width: 100%;
//                 button{
//                     width: 100%;
//                     text-align: center;
//                 }
//             }
//         }
//     }
// }
